/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';



export default function Saques() {
    const component_name = "admin/saques";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", mes: "", tipo: 1 });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef([]);
    const pageRef = useRef({page: 0, offset: 0 });
    const totalRef = useRef(0);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", mes: "", tipo: 1 });


    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        _HandlerSearchChange();

     }
    //#endregion Init



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = { word: "", mes: "", tipo: 1 });
        searchRef.current = { word: "", mes: "", tipo: 1 };
        _HandlerSearchChange(event);
    }


    const _HandleBuscar = async(event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Financeiro/Extrato/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);

                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _HandlerSearchChange = async(event) => {
        _HandleBuscar();
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Financeiro/Extrato/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }
    //#endregion Handlers



    return (
        <div className="main-content Extrato">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Transferências - Relatórios de bonificações</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                
                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandleClear}>
                                                    <i className="mdi mdi-eraser"></i> &nbsp;Limpar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-primary" onClick={_HandleBuscar}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="mes" type="month" value={search.mes} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                </div>
                                            </div> 

                                        
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Valor</th>
                                                        <th>Impostos</th>
                                                        <th>Origem</th>
                                                        <th>Descrição</th>
                                                        <th>Data </th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{item.tipo===0?'Entrada':'Saída'}</td>
                                                        <td>{Currency(item.valor)}</td>
                                                        <td><span className='impostos'>{item.valor_impostos===0?'':Currency(item.valor_impostos)}</span></td>
                                                        <td>{item.nome}</td>
                                                        <td>{item.description}</td>
                                                        <td>{moment(item.date_created).format('DD/MM/YYYY')}</td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}