/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer} from 'react-big-calendar';
import { AppServer } from '../../../scripts/Loadbalancer';
import moment from 'moment/moment';
import 'moment/locale/pt';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import ReactGA from 'react-ga'; 
import './index.css'


let calendar;

export default function Cronograma() {
    const component_name = "admin/cronograma";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [rpaDate, setRpaDate] = useState("");

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ mes: (new Date().getMonth()+ 1) , ano: (new Date().getFullYear()) });
  

    const localizer = momentLocalizer(moment);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        /*setTimeout(() => {
            setList([{
                id: 1,
                title: 'Long Event',
                start: new Date(2024, 7, 15),
                end: new Date(2024, 7, 15),
              }])
        }, 1000);*/

        LoadEvents();
     }
    //#endregion Init


    //#region Loaders
     const LoadEvents = async() => {
        await Req("api/FinanceiroMaster/Payments", "POST", searchRef.current, user.token).then(data => {
            if(Array.isArray(data)) {
                let events = [];

                data.map((item, i) => {
                    events.push({
                        id: i,
                        title: Currency(item.valor),
                        start: moment(item.date_to_pay).toDate(),
                        end: moment(item.date_to_pay).toDate()
                    });
                });

                setList(events);
            }
        }).catch(err => {

        });
    }
    //#endregion Loaders


    //#region Handlers
    const OnNavigate = (newDate) => {
        searchRef.current = { mes: (newDate.getMonth()+ 1) , ano: (newDate.getFullYear()) }
        LoadEvents();
    }


    const _HandleRpaChange = (event) => {
        setRpaDate(event.target.value);
    }


    const _HandleRpa = async() => {
        //console.log("_HandleRpa");
        if(rpaDate === "") {
            window.swal("Alerta", "Escolha uma data para emissão", "error");
            return;
        }

        let url = "api/FinanceiroMaster/Rpa";

        fetch(AppServer(url) + url, {
            method: "POST",
            headers: {
                'Authorization': user.token
            },
            body: JSON.stringify({date_to_pay: rpaDate})
        })
        .then(response => {
            // Verifica se a resposta foi bem-sucedida
            if (!response.ok) {
                throw new Error('Erro ao fazer download do arquivo');
            }
            return response.blob(); // Converte a resposta para um Blob
        })
        .then(blob => {
            // Cria um link temporário para iniciar o download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob); // Cria uma URL para o Blob
            link.download = "RPA_" + moment(rpaDate).format('YYYY-MM-DD') + ".xls"; // Define o nome do arquivo a ser baixado
            document.body.appendChild(link);
            link.click(); // Simula o clique no link para iniciar o download
            document.body.removeChild(link); // Remove o link da página
        })
        .catch(error => {
            console.error('Erro ao fazer download:', error);
        });

    }
    //#endregion Handlers


    return (
        <>
        <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="rpa_div">
                                        <div><input id="rpaDate" type="date" className="form-control" value={rpaDate} onChange={_HandleRpaChange} /></div>
                                        <div><button type="button" className="btn btn-primary" onClick={_HandleRpa}><i className="mdi mdi-star-plus"></i> &nbsp;Emitir RPA</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                
                                <div className="card">
                                    <div className="card-body">
                                        <Calendar
                                            defaultDate={new Date()}
                                            localizer={localizer}
                                            events={list}
                                            style={{ height: "600px" }}
                                            onNavigate={OnNavigate}
                                            messages={{
                                                week: 'Semana',
                                                work_week: 'Semana de trabalho',
                                                day: 'Dia',
                                                month: 'Mês',
                                                previous: 'Anterior',
                                                next: 'Próximo',
                                                today: 'Hoje',
                                                agenda: 'Pagamentos'
                                            }}
                                            formats={{
                                                dayHeaderFormat: (date, culture, localizer) =>
                                                localizer.format(date, 'D', culture),
                                            }}
                                        />
                                    </div>
                                </div>
                               
                            </div>   
                        </div>
                    </div>
                    
                </div> 
            </div>
        </div>

        </>
    )
}
