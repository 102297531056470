/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { AppServer } from '../../../scripts/Loadbalancer';
import moment from 'moment/moment';
import './index.css';

let current_element;

const categorias = [
    {categoria_id: 1, nome: "Painel Administrativo"},
    {categoria_id: 2, nome: "Plataforma SUNUNDRIVE"},
    {categoria_id: 3, nome: "Apresentacao do Negócio"},
    {categoria_id: 4, nome: "Assuntos Diversos"},
]



export default function Lista() {
    const component_name = "admin/material_promocional";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [video, setVideo] = useState({video_id: "", nome: "", url: "", categoria_id: 1});
    const [search, setSearch] = useState({ word: "", categoria_id: "" });

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadList();
        setTimeout(() => { forceUpdate() }, 300);
    }
    //#endregion Init



    //#region Loaders
    const LoadList = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Treinamentos/List/0/0/0", "POST", search, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                setList(data.list);
                setTimeout(() => { forceUpdate() }, 300);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE002)", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setVideo(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleSChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandlerSearchChange = () => {
        LoadList();
    }

    const _HandleAdd = () => {
        setPageLoading(true);

        let _video = {...video};
        _video.url = ClearURLYoutube(_video.url);

        Promise.resolve(new Request().Run("api/Treinamentos/Save", "POST", _video, user.token, 0))
        .then(async(data) => {
            current_element = "";;
            if(data.toString() !== "error") {
                LoadList();
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE003)", "error");
            }
            setVideo({video_id: "", nome: "", url: "", categoria_id: 1});
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE004)", "error");
        });
    }


    const _HandleDelete = (item, index) => {
        Promise.resolve(new Request().Run("api/Treinamentos/Delete", "POST", {video_id: item.video_id}, user.token, 0))
        .then(async(data) => {
            current_element = "";
            if(data.toString() !== "error") {
                let _list = [...list];
                _list.splice(index, 1);
                setList(_list);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE003)", "error");
            }
          
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE004)", "error");
        });
    }
    //#endregion Handlers



    //#region Functions
    const ClearURLYoutube = (url) => {
        let urlObj = new URL(url);
        let vParam = urlObj.searchParams.get('v');
    
        urlObj.search = '';

        if (vParam) {
            urlObj.searchParams.set('v', vParam);
        }
    
        return urlObj.toString();
    }
    //#endregion Functions


    return (
        <div className="main-content Treinamentos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        
                            <div className={'card ' + (user.nivel>0?'col-sm-12':'col-sm-9')}>
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Vídeos de Treinamento</h4>

                                    {user.nivel >= 0 && (
                                        <div className="row"> 
                                            <div className="col-xl-12"> 
                                                

                                                <div className="align-right mr-10">
                                                    <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                        <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                    </button>
                                                </div>

                

                                                <div className="search-box align-right mr-10">
                                                    <div className="position-relative">
                                                        <select id="categoria_id" className="form-select" value={search.categoria_id} onChange={_HandleSChange}>
                                                            <option value="">Todas Categorias</option>
                                                            {
                                                                Array.isArray(categorias) && categorias?.length > 0 && categorias.map((item, i) => (
                                                                    <option key={'tipo_'+item.categoria_id} value={item.categoria_id}>{item.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div> 

                                                <div className="search-box align-right mr-10">
                                                    <div className="position-relative">
                                                        <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleSChange}/>
                                                        <i className="bx bx-search search-icon"></i>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                       
                                    )}
                                </div>

                                <div className="card-body pb-2">
                                    <div className='row'>
                                        <div>
                                            <div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="row gallery-wrapper">
                                                        { Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                                            
                                                                <div className="element-item col-xl-4 col-sm-6 " data-category="video">
                                                                    <div className="gallery-box card">
                                                                        <div className="gallery-container ratio ratio-16x9">
                                                                            <iframe src={'https://www.youtube.com/embed/' + item.url.replace('https://www.youtube.com/watch?v=','').replace("https://youtu.be/","")} title={item.nome} allowfullscreen></iframe>
                                                                        </div>

                        
                                                                        <div className="box-content p-3">
                                                                            <h5 className="title">{item.nome}</h5>
                                                                            <p className="post"> <a  className="text-body">{categorias.find(c => c.categoria_id === item.categoria_id)?.nome}</a></p>
                                                                          
                                                                        </div>

                                                                        
                                                                    </div>

                                                                    {user.nivel===0 && (<button className="btn btn-primary btn-v-delete" onClick={(e) => _HandleDelete(item, i)}><i className="fa fa-trash"></i>
                                                                        </button>)}
                                                                </div>         
                                                        ))}
                                                        </div> 

                                                        
                                                            
                                                    </div>
                                                </div>
                                                <br /> <br />                     
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>



                            <div className={'col-sm-3 ' + (user.nivel>0?'hide':'')} >
                                <div className="card p-3">
                                    <div>
                                        <center>
                                        <h4>Adicionar Vídeo</h4>
                                        </center>
                                    </div>
                                    <br />   


                                    <div>
                                        <label>Nome do Vídeo</label>
                                        <input id="nome" className="form-control" value={video.nome} onChange={_HandleChange}/>       
                                    </div> 
                                    <br />   

                                    <div>
                                        <label>URL do Vídeo</label>
                                        <input id="url" className="form-control" value={video.url} onChange={_HandleChange}/>       
                                    </div> 
                                    <br />  

                                    <div>
                                        <label>Categoria</label>
                                        <select id="categoria_id" className="form-select" value={video.categoria_id} onChange={_HandleChange}>
                                            {
                                                Array.isArray(categorias) && categorias?.length > 0 && categorias.map((item, i) => (
                                                    <option key={'qtipo_'+item.categoria_id} value={item.categoria_id}>{item.nome}</option>
                                            ))
                                            }    
                                        </select>     
                                    </div> 
                                    <br />               

                                    <div>

                                        <button type="button" className={'btn btn-primary ' + (user.nivel>0 || video.nome === "" || video.url === "" || video.topico === "" ?'hide':'')} style={{width: '100%'}} onClick={_HandleAdd}>
                                            <i className="mdi mdi-star-plus"></i> &nbsp;Enviar Vídeo
                                        </button>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}