/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useNavigate } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';
import { Currency } from '../../../scripts/StringUtils';


export default function Cliente() {
    const component_name = "admin/home_cliente";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [banner, setBanner] = useState({});
    const [image, setImage] = useState("");
    const [info, setInfo] = useState({
        total: 0,
        bloqueado: 0,
        engajamento: 0,
        maxLevel: 0,
        series: [],
        categorias: [],
        server: "",
        pixOk: false
    });
    const hiddenFileInput = useRef(null);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        setTimeout(async() => { await Invalidate('profile_image');}, 10);
        LoadHome();
        LoadBanner();
    }
    //#endregion Init


    //#region Loader
    const LoadHome = async() => {
        await Req("api/Dashboard/Load", "POST", "", user?.token).then(data => {
            //console.log(data);

            let _engajamento = data.niveis.reduce(function (acc, obj) { return acc + obj.total; }, 0);

            let _series = [
                { name: "Semestre Atual", data: [0,0,0,0,0,0] }, 
                { name: "Semestre Anterior", data: [0,0,0,0,0,0] }
            ];

            let meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
            let _categories = [];

     

            for(let i=0; i<12; i++) {
                let b = (13 + i + (new Date().getMonth()))%12;
                let d = moment(new Date()).subtract((11-i), "months").format("YYYY-MM-01");

                let _d = data.series.find(s => s.mes === d);
             
                if(_d) {
                    _series[0].data[i%6] = _d.valor;
                }

                if(i >= 6) {
                    _categories.push(meses[b] + "/" + meses[((b<6?b+12:b)-6)%12]); 
                }
            }


            //console.log(_series, _categories);

            let chart;
            let chartBarColors;
            let options = { 
                series: _series, 
                chart: { 
                    height: 450, 
                    type: "area",
                    zoom: { enabled: false }
                }, 
                dataLabels: { 
                    enabled: false
                }, 
                stroke: { 
                    curve: "smooth" 
                }, 
                colors: chartBarColors = getChartColorsArray("area_chart_spline"), 
                xaxis: { 
                    /*type: "datetime", */
                    categories: _categories
                }, 
                tooltip: { 
                    x: { format: "dd/MM/yy HH:mm" } 
                } };

            (chart = new window.ApexCharts(document.querySelector("#area_chart_spline"), options)).render();

            setInfo({
                total: data.valores.valor, 
                transferido: data.valores.transferido, 
                valor_bloqueado: data.valores.valor_bloqueado, 
                engajamento: _engajamento, 
                maxLevel: Math.max(...data.niveis.map(o => o.level)),
                plano: data.plano,
                server: data.server,
                pixOk: data.pixOk
            })
        }).catch(err => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde!", "error");
        });
    }


    const LoadBanner = async(id = 0) => {
        await Req("api/Marketing/Load/" + id, "GET", "", user?.token).then(data => {
            if(data?.img && data?.img !== "") {
                setBanner(data);

                setTimeout(() => {LoadBanner(data.banner_id)}, 30000);
            }
        }).catch(err => {

        });
    }
    //#endregion Loader


    //#region Handlers
    const _HandleGoTo = (page) => {
        navigate(page);
    }


    const _HandleUpload = (event) => {
        hiddenFileInput.current.click();
    }


    const onImageChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            UploadImages(e);
        };
        reader.readAsDataURL(files[0]);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.OnEnd = async(result) => {
            let arr = JSON.parse(result.img);
            c++;

            if(c === event.target.files.length) {
                setPageLoading(false);
                await Invalidate('profile_image');
                //forceUpdate();
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://painel.sunundrive.com/api/Dashboard/Upload", user.token);
    }


    const _HandleImageError = (event) => {
        event.target.src = "assets/images/users/avatar-3.jpg";
    }


    const _HandleCopyToClipboard = () => {
        navigator.clipboard.writeText('https://sunundrive.com/Home/'+user?.guid);
        window.swal("Sucesso", "Link copiado para área de transferência", "success");
    }


    const _HandlerRequestTransfer = async () => {
        if(info?.valor_liberado<=0) {
            window.swal("Alerta", "Sem saldo disponível para transferência", "error");
            return;
        }


        window.swal({
            title: "Alerta",
            text: "Deseja transferir o valor de " + Currency(info?.valor_liberado||0),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#008C00',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: false
         },
         function(isConfirm){
            if (isConfirm){
                Req("api/Dashboard/RequestTransfer", "POST", {guid: user?.guid, valor: info?.valor_liberado}, user?.token).then(data => {
                    if(data.toString() === "loaded") {
                        LoadHome();
                        window.swal("Sucesso", "Solicitação de transferência concluída, a transferência pode levar até 24 horas para ser efetivada.", "success");
                    } else {
                        window.swal("Erro", "Não foi possível concluir sua solicitação, tente novamente mais tarde!", "error");
                    }
                }).catch(err => {
                    window.swal("Erro", "Não foi possível concluir sua solicitação, tente novamente mais tarde!", "error");
                });

              
            } else {
                window.swal("Cancelado", "Solicitação cancelada", "error");
            }
        }); 
    }


    const _HandleConfigPix = () => {
        navigate("/Perfil");
    }
    //#endregion Handlers




    //#region Functions
    const getChartColorsArray = (e) => {
        if (console.log(e), null !== document.getElementById(e)) { 
            var t = document.getElementById(e).getAttribute("data-colors");
            return (t = JSON.parse(t)).map(function (e) { var t = e.replace(" ", "");
            if (-1 === t.indexOf(",")) { var a = getComputedStyle(document.documentElement).getPropertyValue(t);
            return a || t } var r = e.split(",");
            return 2 != r.length ? t : "rgba(" + getComputedStyle(document.documentElement).getPropertyValue(r[0]) + "," + r[1] + ")" }) 
        }
    }
    //#endregion Functions

    return (
        <div className="row DashboardCliente">
            <div className="row">
                <div className="col-xl-9">
                    <div className="card">


                        <div className="card-body">
                            {
                                banner?.img && banner?.img !== "" && (
                                    <center>
                                    <a href={banner.link!==''?banner.link:'#'} target="_blank">
                                    <img src={'https://painel.sunundrive.com/storage/banners/'+banner?.img} alt={banner?.descrition} title={banner?.descrition} style={{border:'2px #000 solid'}}/>
                                    </a>
                                    <hr />
                                    </center>
                                )
                            }


                            <div className="col-sm-12">
                                <h2>Painel Financeiro de Afiliado</h2>
                                <label className="_visually-hidden" for="affiliate_link">Link de afiliado</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="affiliate_link" placeholder="" readOnly={true} value={'https://sunundrive.com/Home/'+user?.guid}/>
                                    <div className="input-group-text">
                                        <button className='btn-transparent' onClick={_HandleCopyToClipboard}><i className='uil uil-copy'></i></button>
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="row mt-4">
                                <div className="col-xl-4 col-sm-6">
                                    <div className="card border">
                                        <div className="card-body p-3">
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className="bx bxs-wallet h1 mb-0 text-warning"></i>
                                                    </div>
                                                  
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className="text-body">Total Transferido</a></h5>
                                                        <p className="text-primary text-truncate mb-0">{Currency(info?.transferido||0)}</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className="pointer"><i className="mdi mdi-clock"></i> Regras</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                

                                <div className="col-xl-4 col-sm-6">
                                    <div className="card border">
                                        <div className="card-body p-3">
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className="bx bxs-notification-off h1 mb-0 text-warning"></i>
                                                    </div>
                                                 
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className="text-body">Valor Bloqueado</a></h5>
                                                        <p className="text-danger text-truncate mb-0">{Currency(info?.valor_bloqueado||0)}</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className="pointer"><i className="mdi mdi-clock"></i> Tempo de espera</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    

                                <div className="col-xl-4 col-sm-6">
                                    <div className={"card border-secondary border " + (!info.pixOk?'bg-danger':'')}>
                                        <div className="card-body p-3 pointer" onClick={_HandleConfigPix}>
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className={"bx bxs-dollar-circle h1 mb-0 "+ (!info.pixOk?'bg-danger text-white':'text-warning')}></i>
                                                    </div>
                                                    
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className={(!info.pixOk?'bg-danger text-white':'text-black')}>Transferência Automática</a></h5>
                                                        <p className={" text-truncate mb-0 " + (!info.pixOk?'text-black':'text-muted')}>Clique aqui</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className={"pointer " + (!info.pixOk?'bg-danger text-white':'')}><i className="mdi mdi-clock"></i> {(!info.pixOk?'Configure seu Pix':'Atualize seu Pix')}</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br />

                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Comparativo dos últimos 6 meses</h4>
                                    </div>
                                <div className="card-body">                                        
                                    <div id="area_chart_spline" data-colors='["#0082C9", "--bs-success"]' className="apex-charts" dir="ltr"></div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="user-profile-img">
                                <img src={"assets/images/" + user.current_rank + ".png"} className="profile-img profile-foreground-img rounded-top" style={{height: "120px;"}} alt="" />
                                <div className="overlay-content rounded-top">
                                    <div>
                                        <div className="user-nav p-3">
                                            <div className="d-flex justify-content-end">
                                                <div className="dropdown">
                                                    <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bx bx-dots-horizontal font-size-20 text-white"></i>
                                                    </a>

                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item pointer" onClick={_HandleUpload}>Enviar Foto</a></li>
                                                        <li><a className="dropdown-item" href="/Perfil">Cadastrar PIX</a></li>
                                                    </ul>

                                                    <input type="file" ref={hiddenFileInput} onChange={onImageChange} style={{display: 'none'}} accept=".jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="mt-n5 position-relative">
                                <div className="text-center">
                                    {/*<img src="assets/images/users/avatar-3.jpg" alt="" className="avatar-xl rounded-circle img-thumbnail" />*/}
                                    <img src={"storage/users/" + user?.user_id + ".jpg?" + (Invalidator['profile_image'])} alt="" className="avatar-xl rounded-circle img-thumbnail" onError={_HandleImageError}/>
                                    <div className="mt-3">
                                        <h5 className="mb-1">{user?.nome}</h5>
                                        <div>
                                            {
                                                [1,2,3,4,5].map(item => (
                                                    <a className={"badge pointer " + (info?.maxLevel>=item?"badge-soft-success":"badge-soft-warning") + " m-1"}><i className="ui uil-star"></i> Nível {item}</a>
                                                ))
                                            }
                                            

                                        </div>

                                        
                                    </div>

                                </div>
                            </div>

                            <div className="p-3 mt-3">
                                <div className="row text-center">
                                    <div className="col-6 border-end">
                                        <div className="p-1">
                                            <h5 className="mb-1">{Currency(info?.total||0)}</h5>
                                            <p className="text-muted mb-0">Balanço</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-1">
                                            <h5 className="mb-1">{info?.engajamento||0}</h5>
                                            <p className="text-muted mb-0">Engajamento</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 pt-3">
                                    { user?.service_status === 1 && (
                                    <div className="alert alert-success alert-dismissible fade show px-3 mb-0" role="alert">
                                        
                                        <div className="mb-3">
                                            <i className="bx bxs-folder-open h1 text-success"></i>
                                        </div>

                                        <div>
                                            <h5 className="text-success">Painel de serviço</h5>
                                            <p>Acesse sua área para gerencias seus arquivos na nuvem com segurança</p>
                                            <div className="text-center">
                                                <a className="btn btn-link text-decoration-none text-success" href={'https://'+info?.server} target="_blank">Entre aqui <i className="mdi mdi-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    )}


                                    { user?.service_status === 0 && (
                                    <div className="alert alert-danger alert-dismissible fade show px-3 mb-0" role="alert">
                                        
                                        <div className="mb-3">
                                            <i className="bx bx-error h1 text-danger"></i>
                                        </div>

                                        <div>
                                            <h5 className="text-danger">Serviço cancelado</h5>
                                            <p>Seus bônus e serviços estão suspenso e serão removidos permanentemente após 30 dias do vencimento.</p>
                                            <div className="text-center">
                                                <a className="btn btn-link text-decoration-none text-danger" href={'/FormasPagamento'} >Atualizar cartão <i className="mdi mdi-arrow-right"></i></a>
                                                <a className="btn btn-link text-decoration-none text-black" href={'https://'+info?.server} target="_blank">Entre aqui <i className="mdi mdi-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>

                                <br />
                                <h4>Meu Plano</h4>

                                <p className="text-muted _text-truncate mb-0">{info?.plano?.descricao}</p>
                                <br />

                                <div className="card border shadow-none mb-2">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                    <i className="uil uil-bag-alt"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-15 text-truncate mb-1">{info?.plano?.nome}</h5>
                                                <p className="text-muted _text-truncate mb-0">{info?.plano?.status===1?'Ativo':'Cancelado'}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                
                                <div className="card border shadow-none mb-2">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-info text-info font-size-24">
                                                    <i className="uil uil-dollar-sign"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-15 text-truncate mb-1">Valor</h5>
                                                <p className="text-muted _text-truncate mb-0">{Currency(info?.plano?.valor)}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="card border shadow-none mb-2">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-primary text-primary font-size-24">
                                                    <i className="mdi mdi-calendar-cursor"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-15 text-truncate mb-1">Aquisição</h5>
                                                <p className="text-muted _text-truncate mb-0">{moment(info?.plano?.date_created).format("DD/MM/YYYY")}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="card border shadow-none mb-2">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-warning text-warning font-size-24">
                                                    <i className="mdi mdi-calendar-clock"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <h5 className="font-size-15 text-truncate mb-1">Renovação</h5>
                                                {info?.plano?.status===1 ? (
                                                    <p className="text-muted _text-truncate mb-0">{moment(info?.plano?.date_expires).format("DD/MM/YYYY")}</p>
                                                ) : (
                                                    <p className="text-muted _text-truncate mb-0">Cancelado</p>
                                                ) }
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>



                              
                            </div>

                            
                        </div>
                        
                    </div>
                </div>
            </div>    
        </div>
    )
}

