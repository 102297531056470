/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request, Req } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';



export default function MinhaRede() {
    const component_name = "admin/minharede";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", nivel: "" });
    const [total, setTotal] = useState(0);
    const [ganho, setGanho] = useState(0);
    const [potencial, setPotencial] = useState(0);
    const [levels, setLevels] = useState([]);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef([]);
    const pageRef = useRef({page: 0, offset: 0 });
    const totalRef = useRef(0);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", nivel: "" });


    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        _HandlerSearchChange();

        LoadGanho();
    }
    //#endregion Init


    //#region Loaders
    const LoadGanho = async() => {
        await Req("api/Financeiro/Bonus", "POST", "", user?.token).then(data => {
            setGanho(data?.ganhos);

            let _bonus = CalcMaxBonus(data?.potencial);
            setPotencial(_bonus);

            let _percent = Math.ceil(data?.ganhos*100/_bonus);

            setLevels(data?.levels)

            setTimeout(() => {
                let options = {
                    series: [_percent], chart: {
                        height: 250, type: "radialBar", sparkline: {
                            enabled: !0
                        }
                    }, colors: ["#0200B0"], plotOptions: {
                        radialBar: {
                            startAngle: -90, endAngle: 90, track: {
                                background: "#e7e7e7", strokeWidth: "90%", margin: 5
                            }, hollow: {
                                size: "65%"
                            }, dataLabels: {
                                name: {
                                    show: !1
                                }, value: {
                                    offsetY: -2, fontSize: "16px"
                                }
                            }
                        }
                    }, grid: {
                        padding: {
                            top: -10
                        }
                    }, labels: ["Storage"]
                };

                let chart = new window.ApexCharts(document.querySelector("#radial-chart"),options);
                chart.render();
            }, 100);
        }).catch(err => {

        })
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: "", nivel: ""});
        searchRef.current = {word: "", nivel: ""};
        _HandlerSearchChange(event);
    }


    const _HandleBuscar = async(event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Financeiro/MinhaRede/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);

                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _HandlerSearchChange = async(event) => {
        _HandleBuscar();
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Financeiro/MinhaRede/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }
    //#endregion Handlers



    //#region Function
    const CalcMaxBonus = (size) => {
        let niveis = [10,5,3,2,1];
        let sum = 0;

        for(let n=0; n<5; n++) {
            sum += niveis[n] * Math.pow(size,n+1);
        }

        return sum;
    }
    //#endregion Funcion

    return (
        <div className="main-content MinhaRede">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Minha Rede</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                
                         
                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-primary" onClick={_HandleBuscar}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="nivel" value={search.nivel} className="form-control rounded bg-light border-0" onChange={_HandleChange}>
                                                        <option value="">Todos Níveis</option>
                                                        <option value="1">Nível 1</option>
                                                        <option value="2">Nível 2</option>
                                                        <option value="3">Nível 3</option>
                                                        <option value="4">Nível 4</option>
                                                        <option value="5">Nível 5</option>
                                                    </select>
                                                </div>
                                            </div> 

                                        
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Nível</th>
                                                        <th>Guid</th>
                                                        <th>Nome</th>
                                                        <th>Desde</th>
                                                        <th>Periodo</th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{item.level}</td>
                                                        <td>{item.guid}</td>
                                                        <td>{item.nome}</td>
                                                        <td>{moment(item.date_created).format('DD/MM/YYYY')}</td>
                                                        <td>{item.months===0?'Mês atual':(item.months===1?'1 Mês': item.months + ' Meses')}</td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="p-3 mt-3">
                                        <h4>Valor Bruto Mensal Estimado</h4>

                                        <p className="_text-truncate mb-0"><h2 className='text-primary'>{Currency(ganho||0)}</h2></p>
                                        <br />

                           
                                        <h5 className="font-size-17"></h5>
                                        <div className="text-center">
                                            <div className="apex-charts" data-colors='["--bs-primary"]' id="radial-chart"></div>
                                            <p className="text-muted mt-4">Ganhos em relação ao potencial</p>
                                        </div>
                                         
                                        
                                        <div className="card border shadow-none mb-2">
                                            <div className="p-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar align-self-center me-3">
                                                        <div className="avatar-title rounded bg-soft-info text-info font-size-24">
                                                            <i className="uil uil-dollar-sign"></i>
                                                        </div>
                                                    </div>

                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1">Valor Desejado</h5>
                                                        <p className="text-muted _text-truncate mb-0">{Currency(potencial||0)}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <hr />             

                                        { 
                                            Array.isArray(levels)  && levels.length > 0 && levels.map((item, i) => (
                                                <div className="card border shadow-none mb-2">
                                                    <div className="p-2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar align-self-center me-3">
                                                                <div className="avatar-title rounded bg-soft-info text-info font-size-24">
                                                                {item.level}
                                                                </div>
                                                            </div>

                                                            <div className="overflow-hidden me-auto">
                                                                <h5 className="font-size-15 text-truncate mb-1">Nivel {item.level} de bonificações</h5>
                                                                <p className="text-muted _text-truncate mb-0">{item.total} afiliados ativos</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        
                                    </div>

                                    
                                </div>
                                
                            </div>
                        </div> 
                       
                    </div>
                </div>
            </div>
        </div>
    )
}