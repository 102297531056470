/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request, Req } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { requestForToken } from '../../../firebaseInit';
import { CacheDB } from '../../../scripts/CacheDB';
import ReactGA from 'react-ga';
import './index.css';
import Cliente from './Cliente';
import MasterAdmin from './MasterAdmin';



let _current_token = "";
let _current_guid = "";

export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [notifyble, setNotifyable] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        await CacheDB.Initialize().then(async(result) => {
            console.log("Cache Initialized");
        }).catch((err) => { 
            console.log(err);
        })


        NotifyMe();
    }
    //#endregion Init



    //#region Notifications
    const NotifyMe = async(event) => {
        let permission = await Notification.permission;
        //console.log(permission);

        if(permission === "denied") return;
        if(permission === "granted") {
            _current_token = await CacheDB.Get('firebase_token');
            _current_guid = await CacheDB.Get('guid');
            NotificationsPermissions();
            return;
        }

        let result = await CacheDB.JGet('notification_permission');

        let isNotify = -1;
        let milliseconds = 0;
        let hasConfig = true;
        let json;

        if(result && result.length > 0) {
            json = JSON.parse(result[0].value);

            milliseconds = parseInt(json.milliseconds);
            console.log(new Date().getTime(), milliseconds, new Date().getTime() - milliseconds, ((new Date().getTime() - milliseconds) > (24*60*60*1000)));

            if((new Date().getTime() - milliseconds) > (24*60*60*1000))
            {
                console.log(json.data);
                isNotify = parseInt(json.data);
            }
        } else {
            isNotify = 0;
            hasConfig = false;
        }


        if(isNotify === 0) {
            window.swal({
                title: "Notificações",
                text: "Deseja receber notificações do seu Painel no navegador?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Sim',
                cancelButtonText: "Não",
                closeOnConfirm: true,
                closeOnCancel: true
                },
                function (isConfirm)
                {
                    if (isConfirm){

                        CacheDB.JSet('notification_permission', {data: 1, milliseconds: new Date().getTime()});

                        NotificationsPermissions();
                    } else {
                     
                        CacheDB.JSet('notification_permission', {data: 0, milliseconds: new Date().getTime()});
                        LoadAlertas();
                    }
            });
        } else if(isNotify === 1) {
            NotificationsPermissions();
        }
    
        
    }


    const NotificationsPermissions = async() => {
        let data = await requestForToken(setTokenFound);
        console.log("Token is", data);
        if (data && (_current_token !== data || _current_guid !== user.guid)) {
            CacheDB.Set("firebase_token", data);
            CacheDB.Set("guid", user.guid);

            Promise.resolve(new Request().Run("api/Dashboard/Token", "POST", {user_id: user.user_id, token: data}, user.token))
            .then((data) => {
                if(data.toString() !== "error") {
                
                } else {
                    console.log("Record Token error");
                }
            }).catch(() => {
                console.log("Record Token error");
            });

            setNotifyable(true);
        } else {
            setNotifyable(false);
        }

        LoadAlertas();
    }
    //#endregions Notifications



    //#region Loaders
    const LoadAlertas = async() => {
        await Req("api/Alertas/Load", "GET", "", user.token).then(data => {
            console.log(data[0]);

            if(data && data.length > 0 && data[0]?.mensagem && data[0]?.mensagem !== "") {
                setTimeout(() => {
                    window.swal("Notificação", data[0]?.mensagem, "info");
                }, 500);
            }
        }).catch(err => {

        });
    }
    //#endregion Loaders

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {user?.nivel === 1 && (<Cliente />)}
                    {user?.nivel === 0 && (<MasterAdmin />)}
                </div>
            </div>



        </div>
    )

}