/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { Cache } from '../../../scripts/Cache';
import { AppServer } from '../../../scripts/Loadbalancer';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import moment from 'moment';
import { CacheDB } from '../../../scripts/CacheDB';
import ReactGA from 'react-ga';
import './index.css';


export function Cadastro() {
    const component_name = "admin/backend_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [sessionId, setSessionId] = useState("");
  
    const [isCpf, setIsCPF] = useState(false);
    const [isSaveable, setIsSaveable] = useState(false);

    const _formUserRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _loadInfo = useRef({});
    const navigate = useNavigate();
   

    let { Guid } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        CacheDB.Initialize().then(async(result) => {
            
        }).catch((err) => { 
           
        });
    }
    //#endregion Init


    //#region Form Handlers
    const OnFormLoaded = async(event, guid = 0) => {
        setPageLoading(true);

        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Guid) {
                let user_data = await new Request().Run("api/Backend/Load?id="+Guid, "GET", "", user.token);
                if(user_data !== undefined && user_data.entidade_id !== null) {
                    await _formUserRef.current.Load(user_data);
                }
            }

            setPageLoading(false);
        });
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        setPageLoading(true);

        let _result = await _formUserRef.current.Post(AppServer()+"api/Backend/SaveServidor", (Guid ? {guid: Guid}: ""), user.token);
        if(_result.toString() === "loaded") {
            setPageLoading(false);
            window.swal("Sucesso", "Servidor Salvo com sucesso!", "success");
        } else {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");
        }
        await CacheDB.Remove("backend_list");
        navigate("/Backend/Lista");
    }
    //#endregion Handlers


    return (
        <div className="CadastroServidor">
            <br /> 

            <br /> 

            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header justify-content-between d-flex align-items-center">
                                <h4 className="card-title">Cadastro de Servidor</h4>
                                <div className="align-right mr-10">
                                    
                                </div>
                            </div>

                            <div className="card-body pb-2">
                               
                                <form name="fuser" onSubmit={(event) => _HandleSubmit('backend_cadastro', event)} autoComplete="off" id="form-contact" className="GeneratedForm" noValidate>
                                    <div className="row">
                                        <div className="col-md-12 pt-1 pb-1">
                                            <FormGenerator ref={_formUserRef} name={'backend_cadastro'} url={'/assets/forms/servidores.json'} execRef={Exec}></FormGenerator>
                                            <div style={{clear:'both'}}></div>
                                            <center>
                                                <button className="btn btn-lg btn-primary mt-3" type="submit"><i className='mdi mdi-tooltip-check-outline'></i> &nbsp; Salvar servidor</button> &nbsp; 
                                            
                                            </center>
                                            <br />
                                        </div> 
                                    </div>
                                </form>
                                
                            </div>
                        </div>

                        <br /><br /><br /><br />
                    </div>


                   
                </div>
            </div>
            <br />
        </div>
)
}