import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { AppServer } from '../../../scripts/Loadbalancer';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import moment from 'moment/moment';
import { IsCPF, IsCNPJ, Uuid, RemoveAcentos, Currency } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';


let cliente_auto_fill = false;
let card_encrypted = "";
let session_id = "";

export function FormasPagamento(props) {
    const component_name = "admin/formas_pagamento";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [sessionId, setSessionId] = useState("");
  
    const [isCpf, setIsCPF] = useState(false);
    const [userPlano, setUserPlano] = useState({});
    const [userCards, setUserCards] = useState([]);
    const [notas, setNotas] = useState([]);

    const _formUserRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _loadInfo = useRef({});
    


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                let wait_pgapi = setInterval( async() => {
                    //TestPipeline();
                    if (window.PagSeguroDirectPayment) {
                        clearInterval(wait_pgapi);
                        await LoadSessionId();
                        Init();
                    } else {
                        console.log("Waiting PagSeguroDirectPayment");
                    }
                }, 300);
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        LoadUserPlano();
        LoadUserCards();
        LoadNotas();
    }
    //#endregion Init



    //#region Loaders
    const LoadUserPlano = async() => {
        await Req("api/FormasPagamento/UserPlano", "GET", "", user.token).then(data => {
            setUserPlano(data);
        }).catch(err => { });
    }


    const LoadUserCards = async() => {
        await Req("api/FormasPagamento/Cards", "GET", "", user.token).then(data => {
            setUserCards(data);
        }).catch(err => { });
    }


    const LoadNotas = async() => {
        await Req("api/FormasPagamento/Notas", "GET", "", user.token).then(data => {
            setNotas(data);
        }).catch(err => { });
    }
    //#endregion Loaders



    //#region Core
    const GetAmount = () => {
        let plano = _formUserRef.current.GetValue("plano_id");
        if (plano) {
            let tmp = plano.name.toString().split("R$")[1];
            tmp = tmp.replace(",", ".").trim();
            return tmp;
        }
        return "9.90";
    }


    const LoadSessionId = async() => {
        await Promise.resolve(new Request().Run("api/FormasPagamento/Session", "GET", ""))
            .then((data) => {
                setSessionId(prev => prev = data);
                window.PagSeguroDirectPayment.setSessionId(data);
                session_id = data;
                setTimeout(() => {
                    LoadPaymentMethods(99.90);
                }, 100)
            });
    }


    const LoadCards = async(data) => {
        let arr = data.paymentMethods.CREDIT_CARD.options;
        let html = "";

        let keys = Object.keys(arr);
        let cards = [];

        keys.map((item, i) => {
            if (arr[item].status == "AVAILABLE") {
                cards.push({ id: arr[item].code, name: arr[item].name });
            }
        });


        _formUserRef.current.Select("brand", cards, "", "", "", "");

        let _brand = await _formUserRef.current.GetValue("brand");
        _formUserRef.current.Value("brand", (_brand && _brand !== ""?_brand.id :cards[0].id));
    }


    const LoadPaymentMethods = (amount) => {
        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: amount,
            success: function (response) {
                // Retorna os meios de pagamento disponíveis.
                LoadCards(response);
            },
            error: function (response) {
                // Callback para chamadas que falharam.
                console.log("error", response);
                return null;
            },
            complete: function (response) {
                // Callback para todas chamadas.
            }
        });
    }


    const ValidCard = (event) => {
        let _validade = _formUserRef.current.GetValue("validade");
        let _cardNumber = _formUserRef.current.GetValue("cardNumber");
        let _brand = _formUserRef.current.GetValue("brand").name.toLowerCase();
        let _cvv = _formUserRef.current.GetValue("cvv");

        try {
            if (_validade.indexOf("/") == -1 || 
                _cardNumber == "" ||
                _brand == "" ||
                _cvv == "" ||
                _cardNumber == null ||
                _brand == null ||
                _cvv == null ||
                _cardNumber == undefined ||
                _brand == undefined ||
                _cvv == undefined
            ) {
                alert("Informações do cartão incorretas!");
                return;
            }

            let _month = _validade.split("/")[0];
            let _year = _validade.split("/")[1];

            let card = window.PagSeguro.encryptCard({
                publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlJzhZxcQ6+bv2YemZheJusvpxMoEvUSF429dvTCWbTdnDoe+9HhCcjgFQgKZPFTmNmXYuLnsP43teU5XDOMASnLVIS08//WeE2XrT0cNXv7pvGGpYaFDZvIczdaQ9aKCOvDG8u8Mv+w31qKrMm1oo+2DGTtVh3fuP1z4VZx0s9aX/9k8tXcRpdAM8kb9rOm76c7ssLR2ySN/8OcmeiFVtLWQ/Lk5n2HClyXyQ6KUMZwC13S8OldIdFFzY86YvjDsMUmyDwZpKWgex8ZgNBz332GsH//UeTA744aEaKoBJJ5rUcAiq/Ea5hoFSy/OmA2nblI4k5xOrMpiNXOzPc6m5wIDAQAB",
                holder: RemoveAcentos(_formUserRef.current.GetValue("cardName")),
                number: _cardNumber,
                expMonth: _month,
                expYear: _year,
                securityCode: _cvv,
              });
              
              card_encrypted = card.encryptedCard;
              console.log(card_encrypted);

              

        } catch (e) { }
    }
    //#endregion Core



    //#region Form Handlers
    const OnFormLoaded = async(event, guid = 0) => {
        //console.log("OnFormLoaded");
        setPageLoading(true);
 
        WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Value("pagamento_tipo", "1");
            await _formUserRef.current.Value("plano_id", "1");
            await _formUserRef.current.Select("uf_codigo", [], "/assets/json/estados.json", "GET", "", "");
            await _formUserRef.current.Value("uf_codigo", 26);
            await _formUserRef.current.Select("plano_id", [], AppServer() + "api/FormasPagamento/Planos", "GET", "", user.token);

            let planos = await _formUserRef.current.GetOptions("plano_id");
            let amount = 99.90;

            let user_data = await new Request().Run("api/FormasPagamento/Load?guid=" + guid, "GET", "", user.token);
            //console.log(user_data);
            if(user_data && user_data.guid !== null && user_data.guid !== "" ) {
                _loadInfo.current = user_data;
                await _formUserRef.current.Load(user_data);
                if(user_data.uf_codigo && user_data.uf_codigo !== "") await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: user_data.uf_codigo }, user.token);
                
                amount = planos.find(p => p.plano_id === parseInt(user_data?.plano_id))?.valor;
            } else {
                await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: 26 }, user.token);
                amount = planos[0].valor;
            }

            if(!amount && amount === "") amount = planos[0].valor;  
        });
        setPageLoading(false);
    }


    const cep = async value => {
        if(!cliente_auto_fill) {
            let _rua = await _formUserRef.current.GetValue("rua");
            if(value.length === 9 ) {
                Promise.resolve(new Request().Run("https://viacep.com.br/ws/" + value.replace('-', '') + "/json", "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        //console.log(data);
                        await _formUserRef.current.Value("rua", data.logradouro);
                        await _formUserRef.current.Value("bairro", data.bairro);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.uf).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: _uf }, user.token);

                        let city = data.localidade;
                        city = city.toLocaleUpperCase();
                        
                        let cities = await _formUserRef.current.GetOptions("cidade_codigo");
                        let _id = cities.find(c => c.name === city).id;
                        await _formUserRef.current.Value("cidade_codigo", _id);
                    }
                }).catch(() => {
                
                });
            }
        }
    }

    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: value }, user.token);
        setPageLoading(false);
    }


    const cpf = async(args) => {
        setIsCPF(IsCPF(args));
    }

    const blur_cpf = async(args) => {
        setIsCPF(IsCPF(args));
        if(!IsCPF(args) && args.length > 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        }
    }




    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers



    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpf");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }

        let _validade = _formUserRef.current.GetValue("validade");
        if(_validade.length < 7) {
            window.swal("Alerta", "A validade deve ter o formato: MM/AAAA", "error");
            return;
        }

        ValidCard();
  

        let tipo = await _formUserRef.current.GetValue("pagamento_tipo");
        if(parseInt(tipo) === 1) {
            let _idx = _formUserRef.current.GetValue("plano_id").idx;
            let _data = {bandeira: _formUserRef.current.GetValue("brand").name.toLowerCase(), encrypted: card_encrypted, plano_idx: _idx };
            //let _result = await _formUserRef.current.Test(AppServer()+"api/FormasPagamento/Card", _data, user.token);
            setPageLoading(true);
            let _result = await _formUserRef.current.Post(AppServer()+"api/FormasPagamento/Sign", _data, user.token);
            if(_result.toString() === "loaded") {
                window.swal("Sucesso", "Cartão Salvo com sucesso!", "success");
                LoadUserCards();
                OnFormLoaded();
                setPageLoading(false);
            } else {
                window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");
                setPageLoading(false);
            }
        } else {
           
        } 
    }


    const _HandleSubmitSign = async () => {

    
        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpf");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }


        let _validade = _formUserRef.current.GetValue("validade");
        if(_validade.length < 7) {
            window.swal("Alerta", "A validade deve ter o formato: MM/AAAA", "error");
            return;
        }

        ValidCard();
  

        let tipo = await _formUserRef.current.GetValue("pagamento_tipo");
        if(parseInt(tipo) === 1) {
            let _idx = _formUserRef.current.GetValue("plano_id").idx;
            let _data = {bandeira: _formUserRef.current.GetValue("brand").name.toLowerCase(), encrypted: card_encrypted, plano_idx: _idx, newSign: "1"};
            setPageLoading(true);
            let _result = await _formUserRef.current.Post(AppServer()+"api/FormasPagamento/Sign", _data, user.token);
            //let _result = await _formUserRef.current.Test(AppServer()+"api/FormasPagamento/Sign", _data, user.token);
            if(_result.toString() === "loaded") {
                window.swal("Sucesso", "Assinatura concluída com sucesso!", "success");
                LoadUserCards();
                LoadUserPlano();
                OnFormLoaded();
                setPageLoading(false);
            } else {
                window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");
                setPageLoading(false);
            }
        } else {
           
        }
    }


    const _HandleRenovar = async(event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpf");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }
  

        let tipo = await _formUserRef.current.GetValue("pagamento_tipo");
        if(tipo === "1") {
           
        } else {
            
        } 
    }


    const _HandleNewCard = event => {
        _formUserRef.current.Clear();
        _formUserRef.current.Value("pagamento_tipo", 1);
        _formUserRef.current.Value("plano_id", 1);
    }


    const _HandleSelectCard = card => {
        //console.log("_HandleSelectCard");
        OnFormLoaded(undefined, card.guid);
        window.scrollTo(0,0);
    }


    const _HandleSetupCard = async card => {
        window.swal({
            title: "Confirmação",
            text: "Deseja realmente ativar esse cartão para próximas cobranças?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: true
         },
         async function(isConfirm){
            if (isConfirm){
                await Req("api/FormasPagamento/CardSetup", "POST", {guid: card.guid}, user.token).then(data => {
                    window.swal("Sucesso", "Cartão ativado para cobranças", "success");
                    LoadUserCards();
                    OnFormLoaded(undefined, card.guid);
                    window.scrollTo(0,0);
                }).catch(err => { });
            } else {
                window.swal("Cancelado", "Ação cancelada", "error");
            }
        });
    }


    const _HandleDeleteCard = card => {
        window.swal({
            title: "Confirmação",
            text: "Deseja realmente ativar esse cartão para próximas cobranças?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: true
         },
         async function(isConfirm){
            if (isConfirm){
                await Req("api/FormasPagamento/CardDelete", "POST", {guid: card.guid}, user.token).then(data => {
                    window.swal("Sucesso", "Cartão apagado com sucesso", "success");
                    LoadUserCards();
                }).catch(err => { });
            } else {
                window.swal("Cancelado", "Ação cancelada", "error");
            }
        });
    }


    const _HandleCancelAssinatura = (assinatura_id) => {
        window.swal({
            title: "Confirmação",
            text: "Deseja realmente cancelar sua assinatura? \n Todos os futuros recebimentos e bônus da rede serão desativados imediatamente",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: true
         },
         async function(isConfirm){
            if (isConfirm){
                setPageLoading(true);
                await Req("api/FormasPagamento/CancelarAssinatura", "POST", {assinatura_id: assinatura_id}, user.token).then(data => {
                    window.swal("Sucesso", "Assinatura cancelada com sucesso", "success");
                    LoadUserPlano();
                    setPageLoading(false);
                }).catch(err => { 
                    setPageLoading(false);
                });
            } else {
                window.swal("Cancelado", "Ação cancelada", "error");
            }
        });
    }
    //#endregion Handlers



    //#region Tests
     const TestPipeline = () => {
        console.log("TestPipeline");
        document.addEventListener('keydown', async function (e) {
            var char = e.keyCode || e.which;
            var letra = String.fromCharCode(char).toLowerCase();
            console.log(letra);
            if (letra === "t") {
                await _formUserRef.current.Value("celular", "(12) 98122-1043");
                await _formUserRef.current.Value("cpf", "315.757.808-10");
                await _formUserRef.current.Value("name", "Felipe Martins dos Santos");
                await _formUserRef.current.Value("email", "fmantipuff@gmail.com");
                await _formUserRef.current.Value("birthDate", "1978-05-10");
                await _formUserRef.current.Value("plano_id", 1);

                //_formUserRef.current.Value("pagamento_tipo", 1);
                await _formUserRef.current.Value("brand", "101");
                await _formUserRef.current.Value("cardNumber", "4111111111111111");
                await _formUserRef.current.Value("cvv", "123");
                await _formUserRef.current.Value("validade", "12/2030");
                await _formUserRef.current.Value("cardName", "Felipe Martins dos Santos");   
                await _formUserRef.current.Value("cpf_holder", "315.757.808-10");
                await _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Value("cidade_codigo", 9726);
                await _formUserRef.current.Value("rua", "Rua Capitão Antonio Raposo Barreto");
                await _formUserRef.current.Value("numero", "207");
                await _formUserRef.current.Value("bairro", "Jardim Bela Vista");
                await _formUserRef.current.Value("cep", "12091-530");
            }

            //ValidCard();
        });
    }
    //#endregion Tests



    return(
        <div className="main-content FormasPagamento">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-xl-8">

                                <div className="card">
                                    <div className="card-body">
                                        <ol className="activity-checkout mb-0 px-4 mt-3">
                                            <li className="checkout-item">
                                                <div className="avatar checkout-icon">
                                                    <div className="avatar-title rounded-circle bg-primary">
                                                        <i className="bx bxs-receipt text-white font-size-20"></i>
                                                    </div>
                                                </div>
                                                <div className="feed-item-list">
                                                    <div>
                                                        <div className="pay-item-top-grid">
                                                            <div>
                                                                <h5 className="font-size-16 mb-1">Informações de Pagamento</h5>
                                                                <p className="text-muted text-truncate mb-4">Preencha todos os campos obrigatórios</p>
                                                            </div>
                                                            <div>
                                                                <button className="btn btn-info mt-3" type="button" onClick={_HandleNewCard}><i className='mdi mdi-credit-card-check'></i> &nbsp; Adicionar Cartão</button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <form name="fuser" onSubmit={(event) => _HandleSubmit('formas_pagamento', event)} autoComplete="off" id="form-contact" className="GeneratedForm"  noValidate>
                                                                <FormGenerator ref={_formUserRef} name={'formas_pagamento'} url={'/assets/forms/formas_pagamento.json'} execRef={Exec}></FormGenerator>
                                                                <div style={{clear:'both'}}></div>
                                                                <center>
                                                                    <button className="btn btn-lg btn-primary mt-3" type="submit" disabled={!isCpf}><i className='mdi mdi-credit-card-check'></i> &nbsp; Salvar cartão</button> &nbsp; 
                                                                    {(!userPlano?.assinatura_id || userPlano?.assinatura_id === "") && (
                                                                    <button className="btn btn-lg btn-outline-primary mt-3" type="button" disabled={!isCpf} onClick={_HandleSubmitSign}><i className='mdi mdi-credit-card-check'></i> &nbsp; Assinar Plano </button>
                                                                    )}
                                                                </center>
                                                            </form>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>

                                               
                                            </li>
                                            <li className="checkout-item">
                                                <div className="avatar checkout-icon">
                                                    <div className="avatar-title rounded-circle bg-primary">
                                                        <i className="mdi mdi-credit-card-check text-white font-size-20"></i>
                                                    </div>
                                                </div>
                                                <div className="feed-item-list">
                                                    <div>
                                                        <h5 className="font-size-16 mb-1">Cartões Salvos</h5>
                                                        <p className="text-muted text-truncate mb-4">Verifique o cartão para cobrança</p>
                                                        <div className="mb-3">
                                                            <div className="row">
                                                                {Array.isArray(userCards) && userCards?.length > 0 && userCards?.map((item, i) => (
                                                                     <div key={'card_'+i} className="col-lg-4 col-sm-6 col-space">
                                                                        <div data-bs-toggle="collapse">
                                                                            <label className="card-radio-label mb-0">
                                                                                <input type="radio" name={"card"+i} id={"card"+i}  className="card-radio-input" checked={item.ativo===1}  onClick={()=> {_HandleSelectCard(item)}}/>
                                                                                <span className="card-radio text-truncate p-3">
                                                                                    <span className="fs-14 mb-4 d-block">{item?.bandeira}</span>
                                                                                    <span className="fs-14 mb-2 d-block">{item?.name}</span>
                                                                                    <span className="text-muted fw-normal text-wrap mb-1 d-block">{item?.cardNumber}</span>
                                                                                    
                                                                                    <span className="text-muted fw-normal d-block">{item?.cidade}-{item?.uf}</span>
                                                                                </span>
                                                                            </label>
                                                                            {item?.ativo === 1 ? (
                                                                                <></>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="edit-btn bg-light rounded pointer" onClick={() => _HandleDeleteCard(item)}> 
                                                                                        <i className="bx bx-trash-alt font-size-16"></i>
                                                                                    </div>

                                                                                    <div className="select-btn bg-light rounded pointer" onClick={() => _HandleSetupCard(item)}> 
                                                                                        <i className="bx bx-check font-size-16"></i>
                                                                                    </div>
                                                                                </> 
                                                                            )}

                                                                            
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                {(!userCards || !Array.isArray(userCards) || (Array.isArray(userCards) && userCards?.length == 0)) &&  (
                                                                    <div className="user_cards">
                                                                         <h4>Nenhum cartão cadastrado</h4> 
                                                                    </div>
                                                                )}      
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                           
                                        </ol>
                                    </div>
                                </div>

                               
                            </div>
                            <div className="col-xl-4">
                                <div className="card checkout-order-summary">
                                    <div className="card-body">
                                        <div className="p-3 bg-light mb-2">
                                            <h5 className="font-size-16 mb-0">Plano Contratado <span className="float-end ms-2">#{userPlano?.plano_id}</span></h5>
                                        </div>
                                        <div className="table-responsive">
                                            {(!!userPlano?.plano_id && userPlano?.plano_id !== '' && userPlano?.plano_id !== 0) && (
                                            <table className="table table-centered mb-0 table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th className="border-top-0" scope="col" colSpan={2}>Descrição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <h5 className="font-size-16 text-truncate"><a href="ecommerce-product-detail.html" className="text-dark">{userPlano?.nome}</a></h5>
                                                            <p className="text-muted mb-0">{userPlano?.descricao}</p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <h5 className="font-size-14 m-0">Vencimento :</h5>
                                                        </td>
                                                        <td>
                                                            {moment(userPlano?.date_expires).format("DD/MM/YYYY")}
                                                        </td>
                                                    </tr>
                                             
                                                    <tr>
                                                        <td>
                                                            <h5 className="font-size-14 m-0">Sub Total :</h5>
                                                        </td>
                                                        <td>
                                                            {Currency(userPlano?.valor)}
                                                        </td>
                                                    </tr>
                           
                                                        
                                                    <tr className="bg-light">
                                                        <td>
                                                            <h5 className="font-size-16 m-0">Total:</h5>
                                                        </td>
                                                        <td>
                                                            {Currency(userPlano?.valor)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            )}


                                            {(!userPlano?.plano_id || userPlano?.plano_id === '' || userPlano?.plano_id === 0) && (
                                                <div className="user_cards">
                                                    <h4>Nenhum plano contratado</h4> 
                                                </div>
                                            )}

                                           
                                            <center> 
                                                <br />
                                                {userPlano?.assinatura_id && userPlano?.assinatura_id !== "" && userPlano?.status === 1 && (
                                                    
                                                    <button className="btn btn-primary mt-3" onClick={() => _HandleCancelAssinatura(userPlano?.assinatura_id)}><i className='mdi mdi-check'></i> &nbsp; Cancelar Assinatura</button>
                                                )}
                                            </center>
                                                    
                                        </div>
                                    </div>
                                </div>

                                <div className="card checkout-order-summary">
                                    <div className="card-header">
                                        Últimas notas fiscais
                                    </div>      

                                    <div className="card-body">
                                        {
                                            notas && notas.length > 0 && notas.map((item, i) => (
                                                <div key={item} className="notaf">
                                                   Download - &nbsp; <a href={'https://www.nf-eletronica.com.br/checanf.asp?ccm=93272&ref='+item.ref+'&tomador='+item.tomador} target="_blank"> <i className="mdi mdi-badge-account-horizontal-outline"></i> {moment(item.date_created).format('DD/MM/YYYY HH:mm')}</a> 
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

        </div>
    )
}