/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { CacheDB } from '../../../scripts/CacheDB';
import { UploadService } from '../../../scripts/UploadService';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactGA, { event } from 'react-ga';
import './index.css';



export default function Index() {
    const component_name = "marketing";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "" });
    const [form, setForm] = useState({ banner_id: "", img: "", description: "", link: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [image, setImage] = useState("");
    const [banner, setBanner] = useState({});
    const listRef = useRef([]);
    const pageRef = useRef({page: 0, offset: 0 });
    const totalRef = useRef(0);
    
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: ""});
    const hiddenFileInput = useRef(null);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".Marketing")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {    
        _HandlerSearchChange();
    }
    //#endregion Init



    //#region Loaders
    const _HandlerSearchChange = async(event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Marketing/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token)).then((data) => {
            setTotal(data.total);
            totalRef.current = data.total;
            let _list = [...data.list];
            listRef.current = listRef.current.concat(_list);

            setList(listRef.current);
            setPageLoading(false);
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
            } 
        }).catch(err => {

        })
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Marketing/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleSChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleChange = (event) => {
        setForm(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleEdit = async(event, guid) => {
        
    }


    const _HandleSelect = async(event, item) => {
        let _list = [...list];
        _list.find(u => u.banner_id === item.banner_id).selected = (event.target.checked ? 1: 0);

        setList(_list);


        await Req("api/Marketing/Ativo", "POST", {banner_id: item.banner_id, selected: (event.target.checked ? 1: 0), img: item.img, description: item.description}, user.token).then(data => {

        }).catch(err => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (NOT003)", "error");
        });
    }




    const _HandleDelete = (event, item) => {
        window.swal({
            title: "Confirmação",
            text: "Deseja realmente apagar esse?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: true
         },
         async function(isConfirm){
            if (isConfirm){
                await Req("api/Marketing/Delete", "POST", {banner_id: item.banner_id}, user.token).then(async data => {
                    window.swal("Sucesso", "Mensagem apagada com sucesso", "success");
                    _HandlerSearchChange();
                }).catch(err => { });
            } else {
                window.swal("Cancelado", "Ação cancelada", "error");
            }
        });
    }


    const _HandleUpload = (event) => {
        hiddenFileInput.current.click();
    }


    const onImageChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            UploadImages(e);
        };
        reader.readAsDataURL(files[0]);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        let _form = { ...form };

        upload.Data = _form;
        upload.OnEnd = async(result) => {
            let arr = JSON.parse(result.img);
            c++;

            if(c === event.target.files.length) {
                setPageLoading(false);
                setForm({ banner_id: "", img: "", description: "", link: "" });
                _HandlerSearchChange();
                //await Invalidate('banner_image');
                //forceUpdate();
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://painel.sunundrive.com/api/Marketing/Upload", user.token);
    }


    const _HandleSetBanner = (item) => {
        setBanner(item);
    }
    //#endregion Handlers


    return (
        <div className="main-content Marketing">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="card">

                                    {
                                        banner?.img && banner?.img !== "" && (
                                            <center>
                                                <br />
                                            <img src={'https://painel.sunundrive.com/storage/banners/'+banner?.img} alt={banner?.descrition} title={banner?.descrition} style={{border:'2px #000 solid'}}/>
                                            <hr />
                                            </center>
                                        )
                                    }

                                    <div className="card-header justify-content-between d-flex align-items-center">
                                        <h4 className="card-title">Central de Marketing</h4>

                                        <div className="row"> 
                                            <div className="col-xl-12"> 
                                    
                

                                                <div className="align-right mr-10">
                                                    <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                        <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                    </button>
                                                </div>
            

                                                <div className="search-box align-right mr-10">
                                                    <div className="position-relative">
                                                        <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleSChange}/>
                                                        <i className="bx bx-search search-icon"></i>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">


                                        <div className="table-responsive">
                                            <InfiniteScroll
                                                dataLength={list.length}
                                                next={_fetchMoreData}
                                                hasMore={hasMore}
                                                loader={
                                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                                        <b>Carregando...</b>
                                                    </p>
                                                }
                                                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                                endMessage={
                                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                                        <b>Não há mais dados</b>
                                                    </p>
                                                }>    


                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:'35px'}}></th>
                                                            <th>Descrição</th>
                                                            <th style={{width:'130px'}}>Data</th>
                                                            <th style={{width:'35px'}}></th>
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                    {list.map((item, i) => (
                                                        <tr key={'tr' + i} className="pointer"> 
                                                            <td><input type="checkbox" className="selected" checked={item.selected===1} onClick={(event) => _HandleSelect(event, item)}/></td>
                                                            <td onClick={(e) => _HandleSetBanner(item)}>{item.description}</td>
                                                            <td onClick={(e) => _HandleSetBanner(item)}>{moment(item.date_created).format('DD/MM/YYYY')}</td>
                                                            <td><i className="fa fa-trash" onClick={(e) => _HandleDelete( e, item)}></i></td>
                                                        </tr>
                                                    ))}
                                                    
                                                    </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-3">
                                
                                <div className="card p-3">
                                    <div>
                                        <center>
                                        <h5>Adicionar Banner</h5>
                                        </center>
                                    </div>
                                    <br />  

                                    <div>
                                        <label>Descrição</label>
                                        <textarea id="description" name="description" style={{ height: '150px' }} className={'form-control fg-input '} defaultValue={form.description}  value={form.description} onChange={_HandleChange} required>{form.mensagem}</textarea>     
                                    </div> 
                                    <br />  
                                                      
                                    <div>
                                        <label>Link</label>
                                        <input type="text" id="link" name="link" className={'form-control fg-input '} value={form.link} onChange={_HandleChange} />  
                                    </div> 
                                    
                                    <input type="file" ref={hiddenFileInput} onChange={onImageChange} style={{display: 'none'}} accept=".jpg,.gif,.png"/>
                                    <button className="btn btn-primary mt-3" onClick={_HandleUpload} disabled={form.description===''}><i className='mdi mdi-star-minus'></i> &nbsp; Upload de Imagem</button>
                                </div>
                                        
                                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}