/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { Cache } from '../../../scripts/Cache';
import { AppServer } from '../../../scripts/Loadbalancer';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import moment from 'moment';
import { IsCPF, IsCNPJ, Uuid, RemoveAcentos, Currency } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';

let card_encrypted = "";
let session_id = "";
let cliente_auto_fill = false;

let query = new URLSearchParams(window.location.search);
let query_plan_id = query.get('plan_id');


export function CadastroVip() {
    const component_name = "admin/cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [sessionId, setSessionId] = useState("");
  
    const [isCpf, setIsCPF] = useState(false);
    const [isSaveable, setIsSaveable] = useState(false);

    const _formUserRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _loadInfo = useRef({});
    const navigate = useNavigate();
   

    let { Guid } = useParams();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        //TestPipeline();
    }
    //#endregion Init


    //#region Loaders

    //#endregion Loaders


    




    //#region Form Handlers
    const OnFormLoaded = async(event, guid = 0) => {
        console.log("OnFormLoaded");

        setPageLoading(true);

        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Guid) {
                let user_data = await new Request().Run("api/Clientes/Load?id="+Guid, "GET", "", user.token);
                if(user_data !== undefined && user_data.entidade_id !== null) {
                    await _formUserRef.current.Load(user_data);
                }
            }

            await _formUserRef.current.Value("plano_id", (query_plan_id?query_plan_id:"7"));
            await _formUserRef.current.Select("uf_codigo", [], "/assets/json/estados.json", "GET", "", "");
            await _formUserRef.current.Value("uf_codigo", 26);
            await _formUserRef.current.Select("plano_id", [], AppServer() + "api/FormasPagamento/Planos", "GET", "", user.token);
            await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: 26 }, user.token);
            
            setPageLoading(false);

            
        }).catch(err => {
            console.log(err);
        })
    }


    const cep = async value => {
        if(!cliente_auto_fill) {
            let _rua = await _formUserRef.current.GetValue("rua");
            if(value.length === 9 ) {
                Promise.resolve(new Request().Run("https://viacep.com.br/ws/" + value.replace('-', '') + "/json", "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        //console.log(data);
                        await _formUserRef.current.Value("rua", data.logradouro);
                        await _formUserRef.current.Value("bairro", data.bairro);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.uf).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: _uf }, user.token);

                        let city = data.localidade;
                        city = city.toLocaleUpperCase();
                        
                        let cities = await _formUserRef.current.GetOptions("cidade_codigo");
                        let _id = cities.find(c => c.name === city).id;
                        await _formUserRef.current.Value("cidade_codigo", _id);
                    }
                }).catch(() => {
                
                });
            }
        }
    }

    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: value }, user.token);
        setPageLoading(false);
    }


    const cpfcnpj = async(args) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 0) {
            await _formUserRef.current.Value("cpf", args);
        } 
    }

    const blur_cpfcnpj = async(value) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 1) {
            if(!IsCNPJ(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CNPJ informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    setIsSaveable(false);
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CNPJ já existente", "error");
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        } else {
            if(!IsCPF(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CPF informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CPF já existente", "error");
                    setIsSaveable(false);
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        }
    }


    const cpf = async(args) => {
        setIsCPF(IsCPF(args));
    }

    const blur_cpf = async(args) => {
        setIsCPF(IsCPF(args));
        if(!IsCPF(args) && args.length > 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        }
    }



    const pessoa = value =>  {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CNPJ", mask: "99.999.999/9999-9"});
        } else {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CPF", mask: "999.999.999-99"});
        }
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let _senha = await _formUserRef.current.GetValue("senha");
        if(_senha !== "" && _senha.length < 10) {
            window.swal("Alerta", "Sua senha deve ter mais que 10 caracteres", "warning");
            return;
        }

        if(scorePassword(_senha) <= 75) {
            window.swal("Alerta", "Senha senha é muito fraca, tente novamente!", "warning");
            await _formUserRef.current.Value("senha", "");
            await _formUserRef.current.Value("resenha", "");
            return;
        }


        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpfcnpj");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }

  

        let _idx = _formUserRef.current.GetValue("plano_id").idx;
        let _data = {affiliate: "", plano_idx: _idx, isVip: 1 };

        setPageLoading(true);
        let _result = await _formUserRef.current.Post(AppServer()+"api/Cadastro/SignVip", _data, user.token);
        if(_result.toString() === "loaded") {
            setPageLoading(false);
            window.swal("Sucesso", "Cadastro realizado com sucesso!", "success");
            navigate("/");
        } else if(_result.toString() === "exists") {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");
        } else if(_result.toString() === "password_problem") {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro! Há um problema com a validação da senha.", "warning");
        } else {
            setPageLoading(false);
            window.swal("Alerta", "Ocorreu um erro no seu cadastro tente novamente mais tarde!", "warning");
        }
       
    }


    const _HandleBack = () => {
        navigate("/");
    }
    //#endregion Handlers


    //#region Functions
    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions



    //#region Tests
    const TestPipeline = () => {
        console.log("TestPipeline");
        document.addEventListener('keydown', async function (e) {
            var char = e.keyCode || e.which;
            var letra = String.fromCharCode(char).toLowerCase();
            //console.log(letra);
            if (letra === "t") {
                await _formUserRef.current.Value("nome", "Felipe Martins dos Santos");
                await _formUserRef.current.Value("email_login", "user22@agenciavitrae.com.br");
                await _formUserRef.current.Value("senha", "xmB7gXy471p0");
                await _formUserRef.current.Value("resenha", "xmB7gXy471p0");
                await _formUserRef.current.Value("pessoa", 0);
                await _formUserRef.current.Value("cpfcnpj", "127.928.300-93");

                await _formUserRef.current.Value("celular", "(12) 98122-1043");
                await _formUserRef.current.Value("cpf", "127.928.300-93");
                await _formUserRef.current.Value("name", "Felipe Martins dos Santos");
                await _formUserRef.current.Value("email", "user22@agenciavitrae.com.br");
                await _formUserRef.current.Value("birthDate", "1978-05-10");
                await _formUserRef.current.Value("plano_id", 1);

                //_formUserRef.current.Value("pagamento_tipo", 1);
                await _formUserRef.current.Value("brand", "102");
                await _formUserRef.current.Value("cardNumber", "4111111111111111");
                await _formUserRef.current.Value("cvv", "391");
                await _formUserRef.current.Value("validade", "07/2032");
                await _formUserRef.current.Value("cardName", "Felipe M Santos");   
                await _formUserRef.current.Value("cpf_holder", "315.757.808-10");
                await _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Value("cidade_codigo", 9726);
                await _formUserRef.current.Value("rua", "Rua Capitão Antonio Raposo Barreto");
                await _formUserRef.current.Value("numero", "207");
                await _formUserRef.current.Value("bairro", "Jardim Bela Vista");
                await _formUserRef.current.Value("cep", "12091-530");
            }

            //ValidCard();
        });
    }
    //#endregion Tests


    return (
            <div className="Cadastro">
                <br /> 

                <br /> 

                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro do Cliente Vip</h4>
                                    <div className="align-right mr-10">
                                        
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('cadastroVip', event)} autoComplete="off" id="form-contact" className="GeneratedForm" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'cadastroVip'} url={'/assets/forms/cadastro_vip.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                
                                                <br />

                                                <center>
                                                    <hr />
                                                    
                                                    <button className="btn btn-lg btn-primary mt-3 submit" type="submit" disabled={!isSaveable}><i className='mdi mdi-credit-card-check'></i> &nbsp; Cadastrar Cliente</button>
                                               
                                                </center>
                                            </div> 
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>

                            <br /><br /><br /><br />
                        </div>


                       
                    </div>
                </div>
                <br />
            </div>
    )
}