/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { Req, Request } from '../../scripts/Request';
import { Cache } from '../../scripts/Cache';
import { AppServer } from '../../scripts/Loadbalancer';
import { WaitObject } from '../../scripts/Wait';
import { FormGenerator } from '../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Button } from "react-bootstrap";
import moment from 'moment';
import { IsCPF, IsCNPJ, Uuid, RemoveAcentos, Currency } from '../../scripts/StringUtils';
import ReactGA, { set } from 'react-ga';
import './index.css';
import { Terms } from './Terms';


let card_encrypted = "";
let session_id = "";
let cliente_auto_fill = false;

let query = new URLSearchParams(window.location.search);
let query_plan_id = query.get('plan_id');


export default function Cadastro() {
    const component_name = "admin/cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const [indicador, setIndicador] = useState("");
  
    const [isCpf, setIsCPF] = useState(false);
    const [termsRead, setTermsRead] = useState(false);
    const [termsScrolled, setTermsScrolled] = useState(false);
    const [isSaveable, setIsSaveable] = useState(false);
    const [scrollArr, setScrollArr] = useState([0, 0]);

    const _formUserRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _loadInfo = useRef({});
    const navigate = useNavigate();
   


    //#region Affiliate
    let { Affiliate } = useParams();

    if(Affiliate!=="" && Affiliate!==null &&Affiliate!==undefined) {
        Cache.Set("affiliate", Affiliate);
    }
    //#endregion Affiliate


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                let wait_pgapi = setInterval( async() => {
                    //TestPipeline();
                    if (window.PagSeguroDirectPayment) {
                        clearInterval(wait_pgapi);
                        await GetSenderHash()
                        await LoadSessionId();
                        Init();
                    } else {
                        console.log("Waiting PagSeguroDirectPayment");
                    }
                }, 300);
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".Cadastro")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        //TestPipeline();

        document.querySelector('.terms').addEventListener('scroll', function() {
            var element = this;
            console.log((element.scrollHeight - element.scrollTop), element.clientHeight)
            if (element.scrollHeight - element.scrollTop < element.clientHeight + 80) {
                setScrollArr([(element.scrollHeight - element.scrollTop), element.clientHeight])
                setTermsScrolled(true);
            }
        });

        if(Affiliate && Affiliate !=="") LoadIndicador();
    }
    //#endregion Init


    //#region Loaders
    const LoadIndicador = async() => {
        await Req("api/Cadastro/Indicador", "POST", {affiliate: Affiliate}, "").then(data => {
            if(data.toString() !== "error" && data.toString() !== "") {
                setIndicador(data);
            } 
        }).catch(err => {
            window.swal("Alerta", "Houve um erro para obter o indicador", "error");
        });
    }
    //#endregion Loaders


    //#region Core
    const GetAmount = () => {
        let plano = _formUserRef.current.GetValue("plano_id");
        if (plano) {
            let tmp = plano.name.toString().split("R$")[1];
            tmp = tmp.replace(",", ".").trim();
            return tmp;
        }
        return "9.90";
    }


    const GetSenderHash = async () => {
        return await window.PagSeguroDirectPayment.onSenderHashReady(function (response) {
            if (response.status === 'error') {
                console.log(response.message);
                return false;
            }
        });
    }


    const LoadSessionId = async() => {
        await Promise.resolve(new Request().Run("api/FormasPagamento/Session", "GET", ""))
            .then((data) => {
                setSessionId(prev => prev = data);
                window.PagSeguroDirectPayment.setSessionId(data);
                session_id = data;
                setTimeout(() => {
                    LoadPaymentMethods(99.90);
                }, 100)
            });
    }


    const LoadCards = async(data) => {
        let arr = data.paymentMethods.CREDIT_CARD.options;
        let html = "";

        let keys = Object.keys(arr);
        let cards = [];

        keys.map((item, i) => {
            if (arr[item].status == "AVAILABLE") {
                cards.push({ id: arr[item].code, name: arr[item].name });
            }
        });


        _formUserRef.current.Select("brand", cards, "", "", "", "");

        let _brand = await _formUserRef.current.GetValue("brand");
        _formUserRef.current.Value("brand", (_brand && _brand !== ""?_brand.id :cards[0].id));
    }


    const LoadPaymentMethods = (amount) => {
        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: amount,
            success: function (response) {
                // Retorna os meios de pagamento disponíveis.
                LoadCards(response);
            },
            error: function (response) {
                // Callback para chamadas que falharam.
                console.log("error", response);
                return null;
            },
            complete: function (response) {
                // Callback para todas chamadas.
            }
        });
    }


    const ValidCard = (event) => {
        let _validade = _formUserRef.current.GetValue("validade");
        let _cardNumber = _formUserRef.current.GetValue("cardNumber");
        let _brand = _formUserRef.current.GetValue("brand").name.toLowerCase();
        let _cvv = _formUserRef.current.GetValue("cvv");

        try {
            if (_validade.indexOf("/") == -1 || 
                _cardNumber == "" ||
                _brand == "" ||
                _cvv == "" ||
                _cardNumber == null ||
                _brand == null ||
                _cvv == null ||
                _cardNumber == undefined ||
                _brand == undefined ||
                _cvv == undefined
            ) {
                alert("Informações do cartão incorretas!");
                return;
            }

            let _month = _validade.split("/")[0];
            let _year = _validade.split("/")[1];

            let card = window.PagSeguro.encryptCard({
                publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlJzhZxcQ6+bv2YemZheJusvpxMoEvUSF429dvTCWbTdnDoe+9HhCcjgFQgKZPFTmNmXYuLnsP43teU5XDOMASnLVIS08//WeE2XrT0cNXv7pvGGpYaFDZvIczdaQ9aKCOvDG8u8Mv+w31qKrMm1oo+2DGTtVh3fuP1z4VZx0s9aX/9k8tXcRpdAM8kb9rOm76c7ssLR2ySN/8OcmeiFVtLWQ/Lk5n2HClyXyQ6KUMZwC13S8OldIdFFzY86YvjDsMUmyDwZpKWgex8ZgNBz332GsH//UeTA744aEaKoBJJ5rUcAiq/Ea5hoFSy/OmA2nblI4k5xOrMpiNXOzPc6m5wIDAQAB",
                holder: RemoveAcentos(_formUserRef.current.GetValue("cardName")),
                number: _cardNumber,
                expMonth: _month,
                expYear: _year,
                securityCode: _cvv,
              });
              
              card_encrypted = card.encryptedCard;
              console.log(card_encrypted);

        } catch (e) { }
    }
    //#endregion Core




    //#region Form Handlers
    const OnFormLoaded = async(event, guid = 0) => {

        WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Value("pagamento_tipo", "1");
            await _formUserRef.current.Value("plano_id", (query_plan_id?query_plan_id:"7"));
            await _formUserRef.current.Select("uf_codigo", [], "/assets/json/estados.json", "GET", "", "");
            await _formUserRef.current.Value("uf_codigo", 26);
            await _formUserRef.current.Select("plano_id", [], AppServer() + "api/FormasPagamento/Planos", "GET", "", user.token);
            await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: 26 }, user.token);


            window.$(".top_pagante .separatorbar_actions").html("<a id='copy_data1' class='pointer'>Copiar dados acima</a>");
            window.$("#copy_data1").on("click", async() => {
                await _formUserRef.current.Value("cpf", await _formUserRef.current.GetValue("cpfcnpj"));
                await _formUserRef.current.Value("email", await _formUserRef.current.GetValue("email_login"));
                await _formUserRef.current.Value("name", await _formUserRef.current.GetValue("nome"));
                await _formUserRef.current.Value("birthDate", await _formUserRef.current.GetValue("nascimento"));
            });


            window.$(".top_pagamento .separatorbar_actions").html("<a id='copy_data2' class='pointer'>Copiar dados acima</a>");
            window.$("#copy_data2").on("click", async() => {
                await _formUserRef.current.Value("cpf_holder", await _formUserRef.current.GetValue("cpf"));
                await _formUserRef.current.Value("cardName", await _formUserRef.current.GetValue("name"));
            });
        });
    }

    const cep = async value => {
        if(!cliente_auto_fill) {
            let _rua = await _formUserRef.current.GetValue("rua");
            if(value.length === 9 ) {
                Promise.resolve(new Request().Run("https://viacep.com.br/ws/" + value.replace('-', '') + "/json", "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        //console.log(data);
                        await _formUserRef.current.Value("rua", data.logradouro);
                        await _formUserRef.current.Value("bairro", data.bairro);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.uf).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: _uf }, user.token);

                        let city = data.localidade;
                        city = city.toLocaleUpperCase();
                        
                        let cities = await _formUserRef.current.GetOptions("cidade_codigo");
                        let _id = cities.find(c => c.name === city).id;
                        await _formUserRef.current.Value("cidade_codigo", _id);
                    }
                }).catch(() => {
                
                });
            }
        }
    }


    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: value }, user.token);
        setPageLoading(false);
    }


    const cpfcnpj = async(args) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 0) {
            await _formUserRef.current.Value("cpf", args);
        } 
    }

    const blur_cpfcnpj = async(value) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 1) {
            if(!IsCNPJ(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CNPJ informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    setIsSaveable(false);
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CNPJ já existente", "error");
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        } else {
            if(!IsCPF(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CPF informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CPF já existente", "error");
                    
                    setIsSaveable(false);
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        }
    }


    const cpf = async(args) => {
        setIsCPF(IsCPF(args));
    }


    const blur_cpf = async(args) => {
        setIsCPF(IsCPF(args));
        if(!IsCPF(args) && args.length > 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        }
    }


    const pessoa = value =>  {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CNPJ", mask: "99.999.999/9999-9"});
        } else {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CPF", mask: "999.999.999-99"});
        }
    }


    const blur_validade = async args => {
        if(args.length < 7) {
            window.swal("Alerta", "A validade deve ter o formato: MM/AAAA", "error");
        }
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let _senha = await _formUserRef.current.GetValue("senha");
        if(_senha !== "" && _senha.length < 8) {
            window.swal("Alerta", "Sua senha deve ter mais que 8 caracteres", "warning");
            return;
        }

        if(scorePassword(_senha) <= 75) {
            window.swal("Alerta", "Senha senha é muito fraca, tente novamente!", "warning");
            await _formUserRef.current.Value("senha", "");
            await _formUserRef.current.Value("resenha", "");
            return;
        }


        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _cpf = _formUserRef.current.GetValue("cpf");
        if(!IsCPF(_cpf)) {
            window.swal("Alerta", "CPF invalido!", "warning");
            return;
        }

        let _validade = _formUserRef.current.GetValue("validade");
        if(_validade.length < 7) {
            window.swal("Alerta", "A validade deve ter o formato: MM/AAAA", "error");
            return;
        }
        

        ValidCard();
  
        
        let tipo = await _formUserRef.current.GetValue("pagamento_tipo");
        if(parseInt(tipo) === 1) {
            let affiliate = await Cache.Get("affiliate");

            if(!affiliate || affiliate == "" || affiliate == null) affiliate = Affiliate;

            let _idx = _formUserRef.current.GetValue("plano_id").idx;
            let _data = {bandeira: _formUserRef.current.GetValue("brand").name.toLowerCase(), encrypted: card_encrypted, affiliate: (affiliate?affiliate :""), plano_idx: _idx };
            //let _result = await _formUserRef.current.Test(AppServer()+"api/Cadastro/Sign", _data, user.token);
            
            setPageLoading(true);
            let _result = await _formUserRef.current.Post(AppServer()+"api/Cadastro/Sign", _data, user.token);
            if(_result.toString() === "loaded") {
                setPageLoading(false);
                window.swal("Sucesso", "Cadastro realizado com sucesso!", "success");
                navigate("/");
            } else if(_result.toString() === "overdue") {
                setPageLoading(false);
                window.swal("Alerta", "A operadora do seu cartão pode demorar algumas horas para processar seu pagamento, você receberá um e-mail quando seu pagamento for aprovado! ", "warning");
                navigate("/");
            } else if(_result.toString() === "exists") {
                setPageLoading(false);
                window.swal("Alerta", "Houve um erro! Tente novamente mais tarde.", "warning");
            } else if(_result.toString() === "password_problem") {
                setPageLoading(false);
                window.swal("Alerta", "Houve um erro! Há um problema com a validação da senha.", "warning");
            } else {
                setPageLoading(false);
                window.swal("Alerta", "Ocorreu um erro no seu cadastro tente novamente mais tarde!", "warning");
            }
        } else {
           
        } 
    }


    const _HandleBack = () => {
        navigate("/");
    }


    const _HandleTerms = (event) => {
        setTimeout(() => {
            setTermsRead(event.target.checked);
        }, 400)
    }
    //#endregion Handlers


    

    //#region Tests
    const TestPipeline = () => {
        console.log("TestPipeline");
        document.addEventListener('keydown', async function (e) {
            var char = e.keyCode || e.which;
            var letra = String.fromCharCode(char).toLowerCase();
            console.log(letra);
            if (letra === "t") {
                await _formUserRef.current.Value("nome", "Felipe Martins dos Santos");
                await _formUserRef.current.Value("email_login", "user24@agenciavitrae.com.br");
                await _formUserRef.current.Value("senha", "xmB7gXy471p0");
                await _formUserRef.current.Value("resenha", "xmB7gXy471p0");
                await _formUserRef.current.Value("pessoa", 0);
                await _formUserRef.current.Value("cpfcnpj", "835.325.480-89");
                await _formUserRef.current.Value("mae", "Joanete Martins");

                await _formUserRef.current.Value("celular", "(12) 98122-1043");
                await _formUserRef.current.Value("cpf", "835.325.480-89");
                await _formUserRef.current.Value("name", "Felipe Martins dos Santos");
                await _formUserRef.current.Value("email", "user24@agenciavitrae.com.br");
                await _formUserRef.current.Value("birthDate", "1978-05-10");
                await _formUserRef.current.Value("plano_id", 7);

                //_formUserRef.current.Value("pagamento_tipo", 1);
                await _formUserRef.current.Value("brand", "102");
                await _formUserRef.current.Value("cardNumber", "4111111111111111");
                await _formUserRef.current.Value("cvv", "391");
                await _formUserRef.current.Value("validade", "07/2032");
                await _formUserRef.current.Value("cardName", "Felipe M Santos");   
                await _formUserRef.current.Value("cpf_holder", "835.325.480-89");
                await _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Value("cidade_codigo", 9726);
                await _formUserRef.current.Value("rua", "Rua Capitão Antonio Raposo Barreto");
                await _formUserRef.current.Value("numero", "207");
                await _formUserRef.current.Value("bairro", "Jardim Bela Vista");
                await _formUserRef.current.Value("cep", "12091-530");
            }

            //ValidCard();
        });
    }
    //#endregion Tests


    //#region Functions
    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions



    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LfIIAIqAAAAAD8ScdUFJ9YcxTmKGYIF_zhwYQZ8" language="pt-BR">
            <div className="Cadastro">
                <br /> 
                <center><img src="/assets/images/logo2.png" alt="" /></center>
                <br /> 

                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastrar nova conta de serviço</h4>
                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-outline-primary mr-10" onClick={_HandleBack}>
                                            <i className="mdi mdi-arrow-left"></i> &nbsp;Login &nbsp;
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    { indicador && indicador !== "" && (
                                        <div>
                                            <center>
                                            <h4>Indicação de <span style={{color:'red'}}>{indicador}</span></h4>
                                            </center>
                                        </div>
                                    )}

                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('entidade', event)} autoComplete="off" id="form-contact" className="GeneratedForm" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'unidade'} url={'/assets/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>

                                                
                                                <Terms />

                                                <center>
                                                    <hr />
                                                    
                                                    {termsRead &&  (<button className="btn btn-lg btn-primary mt-3 submit" type="submit" disabled={!isSaveable}><i className='mdi mdi-credit-card-check'></i> &nbsp; Assinar Plano</button>)}
                                                    {!termsRead && termsScrolled && (<div className="checkTerms">
                                                            <div>
                                                                <input type="checkbox" onClick={_HandleTerms}/>
                                                            </div>
                                                            <div>
                                                                <small>Li e estou ciente dos termos de contratação aceitando-os integralmente.</small>
                                                            </div>
                                                    </div>)}
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
                <br />
            </div>
        </GoogleReCaptchaProvider>
    )
}