/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useNavigate } from 'react-router-dom';
import { UploadService } from '../../../scripts/UploadService';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { Currency } from '../../../scripts/StringUtils';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';


export default function Admin() {
    const component_name = "admin/home_admin";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [image, setImage] = useState("");
    const [banner, setBanner] = useState({});
    const [planos, setPlanos] = useState([]);
    const [info, setInfo] = useState({
        ativos: 0,
        inativos: 0,
        valor_mes: 0,
        valor_bonus: 0,
        assinaturas: [],
        series: [],
        backend: {}
    });
    const hiddenFileInput = useRef(null);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj("body")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        setTimeout(async() => { await Invalidate('profile_image');}, 10);
        LoadHome();
        LoadPlanos();
        LoadBanner();
    }
    //#endregion Init


    //#region Loader
    const LoadPlanos = async() => {
        await Req("api/DashboardAdmin/Planos", "GET", "", user?.token).then(data => {
            setPlanos(data);
        }).catch(err => {

        });
    }


    const LoadHome = async() => {
        await Req("api/DashboardAdmin/Load", "POST", "", user?.token).then(data => {
            //console.log(data);

         
            let _series = [
                { name: "Semestre Atual", data: [0,0,0,0,0,0] }, 
                { name: "Semestre Anterior", data: [0,0,0,0,0,0] }
            ];

            let meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
            let _categories = [];

     

            for(let i=0; i<12; i++) {
                let b = (13 + i + (new Date().getMonth()))%12;
                let d = moment(new Date()).subtract((11-i), "months").format("YYYY-MM-01");

                let _d = data.series.find(s => s.mes === d);
             
                if(_d) {
                    _series[0].data[i%6] = _d.valor;
                }

                if(i >= 6) {
                    _categories.push(meses[b] + "/" + meses[((b<6?b+12:b)-6)%12]); 
                }
            }


            //console.log(_series, _categories);

            let chart;
            let chartBarColors;
            let options = { 
                series: _series, 
                chart: { 
                    height: 450, 
                    type: "area",
                    zoom: { enabled: false }
                }, 
                dataLabels: { 
                    enabled: false
                }, 
                stroke: { 
                    curve: "smooth" 
                }, 
                colors: chartBarColors = getChartColorsArray("area_chart_spline"), 
                xaxis: { 
                    /*type: "datetime", */
                    categories: _categories
                }, 
                tooltip: { 
                    x: { format: "dd/MM/yy HH:mm" } 
                } };

            (chart = new window.ApexCharts(document.querySelector("#area_chart_spline"), options)).render();

            setInfo({
                ativos: data.ativos, 
                inativos: data.inativos, 
                valor_mes: data.valor_mes, 
                valor_bonus: data.valor_bonus, 
                assinaturas: data.assinaturas,
                backend: data.servidores
            })
        }).catch(err => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde!", "error");
        });
    }


    const LoadBanner = async(id = 0) => {
        await Req("api/Marketing/Load/" + id, "GET", "", user?.token).then(data => {
            if(data?.img && data?.img !== "") {
                setBanner(data);

                setTimeout(() => {LoadBanner(data.banner_id)}, 30000);
            }
        }).catch(err => {

        });
    }
    //#endregion Loader


    //#region Functions
    const getChartColorsArray = (e) => {
        if (console.log(e), null !== document.getElementById(e)) { 
            var t = document.getElementById(e).getAttribute("data-colors");
            return (t = JSON.parse(t)).map(function (e) { var t = e.replace(" ", "");
            if (-1 === t.indexOf(",")) { var a = getComputedStyle(document.documentElement).getPropertyValue(t);
            return a || t } var r = e.split(",");
            return 2 != r.length ? t : "rgba(" + getComputedStyle(document.documentElement).getPropertyValue(r[0]) + "," + r[1] + ")" }) 
        }
    }
    //#endregion Functions


    //#region Handlers
    const _HandleUpload = (event) => {
        hiddenFileInput.current.click();
    }


    const onImageChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            UploadImages(e);
        };
        reader.readAsDataURL(files[0]);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.OnEnd = async(result) => {
            let arr = JSON.parse(result.img);
            c++;

            if(c === event.target.files.length) {
                setPageLoading(false);
                await Invalidate('profile_image');
                //forceUpdate();
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload("https://painel.sunundrive.com/api/Dashboard/Upload", user.token);
    }


    const _HandleImageError = (event) => {
        event.target.src = "assets/images/users/avatar-3.jpg";
    }
    //#endregion Handlers


    return (
        <div className="row DashboardCliente">
            <div className="row">
                <div className="col-xl-9">
                    <div className="card">
                        <div className="card-body">
                            {
                                banner?.img && banner?.img !== "" && (
                                    <center>
                                    <a href={banner.link!==''?banner.link:'#'} target="_blank">
                                    <img src={'https://painel.sunundrive.com/storage/banners/'+banner?.img} alt={banner?.descrition} title={banner?.descrition} style={{border:'2px #000 solid'}}/>
                                    </a>
                                    <hr />
                                    </center>
                                )
                            }

                            <div className="row mt-4">
                                <div className="col-xl-4 col-sm-6">
                                    <div className="card border">
                                        <div className="card-body p-3">
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className="bx bxs-wallet h1 mb-0 text-primary"></i>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className="text-body">Faturamento Bruto</a></h5>
                                                        <p className="text-primary text-truncate mb-0">{Currency(info?.valor_mes||0)}</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className="pointer"><i className="mdi mdi-clock"></i> Total dos planos</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                

                                <div className="col-xl-4 col-sm-6">
                                    <div className="card border">
                                        <div className="card-body p-3">
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className="bx bx-dollar h1 mb-0 text-primary"></i>
                                                    </div>
                                                 
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className="text-body">Valor de Bônus no Mês</a></h5>
                                                        <p className="text-danger text-truncate mb-0">{Currency(info?.valor_bonus||0)}</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className="pointer"><i className="mdi mdi-clock"></i> Total pago</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    

                                <div className="col-xl-4 col-sm-6">
                                    <div className="card border-secondary border">
                                        <div className="card-body p-3 pointer">
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i className="bx bx-server h1 mb-0 text-primary"></i>
                                                    </div>
                                                    
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1"><a className="text-black">Backend</a></h5>
                                                        <p className="text-muted text-truncate mb-0">{(info?.backend?.servidores||0)} servidores</p>
                                                    </div>
                                                    <div className="align-self-end ms-2">
                                                        <p className="text-muted mb-0 font-size-13"><a className="pointer"><i className="mdi mdi-clock"></i> {info?.backend?.usuarios} usuários </a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br /><br />

                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Comparativo dos últimos 6 meses</h4>
                                    </div>
                                <div className="card-body">                                        
                                    <div id="area_chart_spline" data-colors='["#0082C9", "--bs-success"]' className="apex-charts" dir="ltr"></div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3">
                    <div className="card">
                        <div className="card-body p-0">
                           
                        <div className="user-profile-img">
                                <img src="assets/images/pattern-bg1.png" className="profile-img profile-foreground-img rounded-top" style={{height: "120px;"}} alt="" />
                                <div className="overlay-content rounded-top">
                                    <div>
                                        <div className="user-nav p-3">
                                            <div className="d-flex justify-content-end">
                                                <div className="dropdown">
                                                    <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bx bx-dots-horizontal font-size-20 text-white"></i>
                                                    </a>

                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item pointer" onClick={_HandleUpload}>Enviar Foto</a></li>
                                                    </ul>

                                                    <input type="file" ref={hiddenFileInput} onChange={onImageChange} style={{display: 'none'}} accept=".jpg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="mt-n5 position-relative">
                                <div className="text-center">
                                    {/*<img src="assets/images/users/avatar-3.jpg" alt="" className="avatar-xl rounded-circle img-thumbnail" />*/}
                                    <img src={"storage/users/" + user?.user_id + ".jpg?" + (Invalidator['profile_image'])} alt="" className="avatar-xl rounded-circle img-thumbnail" onError={_HandleImageError}/>
                                    <div className="mt-3">
                                        <h5 className="mb-1">{user?.nome}</h5>
                                       
                                        
                                    </div>

                                </div>
                            </div>

                            <div className="p-3 mt-3">
                                <div className="row text-center">
                                    <div className="col-6 border-end">
                                        <div className="p-1">
                                            <h5 className="mb-1">{(info?.ativos||0)}</h5>
                                            <p className="text-muted mb-0">Clientes ativos</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="p-1">
                                            <h5 className="mb-1">{info?.inativos||0}</h5>
                                            <p className="text-muted mb-0">Clientes inativos</p>
                                        </div>
                                    </div>
                                </div>

                                <br /><br />
                                <h4>Lista de planos</h4>


                                <br />

                                {
                                    Array.isArray(planos) && planos.length > 0 && planos.map((item, i) => (
                                        <div key={'ass'+i} className="card border shadow-none mb-2">
                                            <div className="p-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar align-self-center me-3">
                                                        <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                            <i className="uil uil-tag"></i>
                                                        </div>
                                                    </div>
        
                                                    <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1">{item?.name}</h5>
                                                        <p className="text-muted _text-truncate mb-0">{Currency(item?.valor||0)} &nbsp; - &nbsp; ({(item?.planos||0)})</p>
                                                    </div>
        
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                               




                               
                            </div>

                            
                        </div>
                        
                    </div>
                </div>
            </div>    
        </div>
    )
}

