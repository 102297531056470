/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { InvalidatorContext } from '../../Context/InvalidatorContext';
import { Request } from '../../scripts/Request';
import { HubContext } from '../../Context/HubContext.js';
import { WaitObject } from '../../scripts/Wait';
import { LinkContainer } from 'react-router-bootstrap';
import $ from 'jquery';
import Menu from '../Menu';
import { useNavigate } from 'react-router-dom';

import './index.css'


let TimerIdleInterval;
let TimerIdle = 0;

const niveis = [
    "Master Admin",
    "Cliente"
]

export default function Header() {
    const component_name = "admin/header";
    const { user, handleLogout, Update } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [info, setInfo] = useState({name: ""});
    const [unidade, setUnidade] = useState(0);
    const [unidades, setUnidades] = useState([]);
    const unidadesRef = useRef([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                $("body").attr("data-layout", "horizontal");
                $("body").attr("data-topbar", "dark");
                $("body").removeClass("bg-white");


                WaitObject(user.nome).then(() => {
                    Init();
                    RegisterHub();
                });
            }
        }, 200);


        return () => {
            clearInterval(TimerIdleInterval);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        let _user = Object.assign({}, user);
        let _info = Object.assign({}, info);
        let _name = _user.nome.split(' ');
        if(_name.length > 1) {
            _info.nome = _name[0] + " " + _name[1];
        }else {
            _info.nome = _name[0];
        }
        setInfo(_info);
    }
    //#endregion Init



    //#region Loaders
    
    //#endregion Loaders


    //#region HubObjects
    const RegisterHub = () => {
        
    }
    //#endregion HubObjects



    //#region Handlers
    const _FullScreen = () => {
        let elem =  document.documentElement;

        if (!$(elem).is(":fullscreen")) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }



    const _Exit = () => {
        handleLogout();
    }



    const _ClearDb = () => {
        localStorage.clear();
        handleLogout();
    }


    const _HandleImageError = (event) => {
        event.target.src = "/assets/images/users/avatar-3.jpg";
    }
    //#endregion Handlers





    return (
        <>
       <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <a className="logo" href='/Home'>
                            <span className="m-logo-lg">
                                <img src="/assets/images/logo2.png" alt="" height="49" />
                            </span>
                        </a>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-bs-toggle="collapse" id="horimenu-btn" data-bs-target="#topnav-menu-content">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                    <div className="topnav active">
                        <Menu />
                    </div>

                </div>

              

                <div className="d-flex">    
                    <div className="d-inline-block btn header-item align-items-center">
                        
                    </div>


                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/*<img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-3.jpg" alt="Header Avatar" />*/}
                            <img className="rounded-circle header-profile-user" src={'/storage/users/' + user?.user_id + '.jpg?' +  (Invalidator['profile_image'])} alt="Header Avatar" onError={_HandleImageError}/>
                            <span className="ms-2 _d-none d-xl-inline-block user-item-desc">
                                <span className="user-name">{user.nome} <i className="mdi mdi-chevron-down"></i></span>
                            </span>
                        </button>
                        <span className="user_nivel">{niveis[user?.nivel]} </span>
                        <div className="dropdown-menu dropdown-menu-end pt-0">
                            <LinkContainer to={'/Perfil'}  exact>
                                <a className="dropdown-item pointer" ><i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Meu perfil</span></a>
                            </LinkContainer>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item pointer" onClick={_FullScreen}><i className="mdi mdi-vibrate text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Fullscreen</span></a>
                            {/*<a className="dropdown-item pointer" onClick={_ClearDb}><i className="mdi mdi-database-sync text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Limpar Base</span></a>*/}
                            <a className="dropdown-item pointer" onClick={_Exit}><i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Sair</span></a>
                        </div>
                    </div>
                </div>
            </div>

                     

        </header>

        <div className="hori-overlay"></div>
        </>
    );
}