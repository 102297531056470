/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { CacheDB } from '../../../scripts/CacheDB';
import { AppServer } from '../../../scripts/Loadbalancer';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import moment from 'moment';
import { IsCPF, IsCNPJ, Uuid, RemoveAcentos, Currency } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';

let card_encrypted = "";
let session_id = "";


let query = new URLSearchParams(window.location.search);
let query_plan_id = query.get('plan_id');


export function Cadastro() {
    const component_name = "admin/cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [sessionId, setSessionId] = useState("");
  
    const [isCpf, setIsCPF] = useState(false);
    const [isSaveable, setIsSaveable] = useState(false);

    const _formUserRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _loadInfo = useRef({});
    const navigate = useNavigate();
   

    let { Guid } = useParams();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        await CacheDB.Initialize().then(async(result) => {
            console.log("Cache Initialized");
           
        }).catch((err) => { 
            console.log(err);
        })

        await CacheDB.Set("cliente_keep", 1);
    }
    //#endregion Init


    //#region Loaders

    //#endregion Loaders


    




    //#region Form Handlers
    const OnFormLoaded = async(event, guid = 0) => {
        setPageLoading(true);

        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Guid) {
                let user_data = await new Request().Run("api/Clientes/Load?id="+Guid, "GET", "", user.token);
                if(user_data !== undefined && user_data.entidade_id !== null) {
                    await _formUserRef.current.Load(user_data);
                }
            }


            await _formUserRef.current.Value("pagamento_tipo", "1");
            await _formUserRef.current.Value("plano_id", (query_plan_id?query_plan_id:"1"));
            await _formUserRef.current.Select("uf_codigo", [], "/assets/json/estados.json", "GET", "", "");
            await _formUserRef.current.Value("uf_codigo", 26);
            await _formUserRef.current.Select("plano_id", [], AppServer() + "api/FormasPagamento/Planos", "GET", "", user.token);
            await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: 26 }, user.token);
            
            setPageLoading(false);
        });
    }


    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], AppServer() + "api/FormasPagamento/Cidades", "POST", { uf_codigo: value }, user.token);
        setPageLoading(false);
    }


    const cpfcnpj = async(args) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 0) {
            await _formUserRef.current.Value("cpf", args);
        } 
    }

    const blur_cpfcnpj = async(value) => {
        let _pessoa = _formUserRef.current.GetValue("cpfcnpj");
        if(parseInt(_pessoa) === 1) {
            if(!IsCNPJ(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CNPJ informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    setIsSaveable(false);
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CNPJ já existente", "error");
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        } else {
            if(!IsCPF(value)) {
                setIsSaveable(false);
                await _formUserRef.current.Value("cpfcnpj", "");
                window.swal("Alerta", "CPF informado é invalido", "error");
            } else {
                let result = await Req("api/Cadastro/CheckCPFCNPJ", "POST", {user: user.user_id, cpfcnpj: value }, user.token);
                if(result.toString() == "exists") {
                    await _formUserRef.current.Value("cpfcnpj", "");
                    window.swal("Alerta", "CPF já existente", "error");
                    setIsSaveable(false);
                } else if(result.toString() == "error") {
                    setIsSaveable(false);
                } else {
                    setIsSaveable(true);
                }
            }
        }
    }


    const cpf = async(args) => {
        setIsCPF(IsCPF(args));
    }

    const blur_cpf = async(args) => {
        setIsCPF(IsCPF(args));
        if(!IsCPF(args) && args.length > 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        }
    }


    const pessoa = value =>  {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CNPJ", mask: "99.999.999/9999-9"});
        } else {
            _formUserRef.current.SetObject("cpfcnpj", {title: "CPF", mask: "999.999.999-99"});
        }
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

    }


    const _HandleBack = () => {
        navigate("/");
    }
    //#endregion Handlers


    //#region Functions
    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions


    return (
            <div className="Cadastro">
                <br /> 

                <br /> 

                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro do Cliente</h4>
                                    <div className="align-right mr-10">
                                        
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('cadastro', event)} autoComplete="off" id="form-contact" className="GeneratedForm" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/assets/forms/cadastro_cliente.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>

                            <br /><br /><br /><br />
                        </div>


                       
                    </div>
                </div>
                <br />
            </div>
    )
}