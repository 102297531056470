import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Layout from './Layout.js';
import Home from './components/Admin/Home';
import { Perfil } from './components/Admin/Perfil';
import { FormasPagamento } from './components/Admin/FormasPagamento';
import Cadastro from './components/Cadastro';
import Extrato from './components/Admin/Financeiro/Extrato';
import Entradas from './components/Admin/Financeiro/Entradas';
import Saques from './components/Admin/Financeiro/Saques';
import MinhaRede from './components/Admin/Financeiro/MinhaRede';
import Clientes from './components/Admin/Clientes';
import { Cadastro as ClienteCadastro } from './components/Admin/Clientes/Cadastro';
import { CadastroVip as ClienteCadastroVip } from './components/Admin/Clientes/CadastroVip';
import Planos from './components/Admin/Planos';
import { Cadastro as PlanoCadastro } from './components/Admin/Planos/Cadastro';
import Backend from './components/Admin/Backend';
import { Cadastro as BackendCadastro } from './components/Admin/Backend/Cadastro';
import { Entradas as MasterEntradas} from './components/Admin/FinanceiroMaster/Entradas';
import { Saidas as MasterSaidas } from './components/Admin/FinanceiroMaster/Saidas';
import MaterialPromocional from './components/Admin/MaterialPromocional';
import Treinamentos from './components/Admin/Treinamentos';
import Support from './components/Admin/Support';
import Notifications from './components/Admin/Notifications';
import Marketing from './components/Admin/Marketing';
import Cronograma from './components/Admin/Financeiro/Cronograma';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname.toLocaleLowerCase().indexOf("/cadastro") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const loadPrestineRef = useRef(false);


    useEffect(() => {
      let wait = setTimeout(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            GetServer();
        }
      }, 200);

    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
           //return children;
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Cadastro" element={<Cadastro />} />
                <Route exact path="/Cadastro/:Affiliate" element={<Cadastro />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/Perfil" element={<ProtectedRoute><Perfil /> </ProtectedRoute>} />
                <Route exact path="/FormasPagamento" element={<ProtectedRoute><FormasPagamento /> </ProtectedRoute>} />
                <Route exact path="/Financeiro/Extrato" element={<ProtectedRoute><Extrato /> </ProtectedRoute>} />
                <Route exact path="/Financeiro/Entradas" element={<ProtectedRoute><Entradas /> </ProtectedRoute>} />
                <Route exact path="/Financeiro/Saques" element={<ProtectedRoute><Saques /> </ProtectedRoute>} />
                <Route exact path="/Financeiro/MinhaRede" element={<ProtectedRoute><MinhaRede /> </ProtectedRoute>} />

                <Route exact path="/Clientes/Lista" element={<ProtectedRoute><Clientes /> </ProtectedRoute>} />
                <Route exact path="/Clientes/Cadastro/:Guid" element={<ProtectedRoute><ClienteCadastro /> </ProtectedRoute>} />
                <Route exact path="/Clientes/CadastroVip" element={<ProtectedRoute><ClienteCadastroVip /> </ProtectedRoute>} />
                <Route exact path="/Clientes/MaterialPromocional" element={<ProtectedRoute><MaterialPromocional /> </ProtectedRoute>} />
                <Route exact path="/Clientes/Treinamentos" element={<ProtectedRoute><Treinamentos /> </ProtectedRoute>} />

                <Route exact path="/Info/Support" element={<ProtectedRoute><Support /> </ProtectedRoute>} />
                <Route exact path="/Info/Notifications" element={<ProtectedRoute><Notifications /> </ProtectedRoute>} />
                <Route exact path="/Info/Marketing" element={<ProtectedRoute><Marketing /> </ProtectedRoute>} />

                <Route exact path="/Planos/Lista" element={<ProtectedRoute><Planos /> </ProtectedRoute>} />
                <Route exact path="/Planos/Cadastro/:Guid" element={<ProtectedRoute><PlanoCadastro /> </ProtectedRoute>} />
                <Route exact path="/Planos/Cadastro/" element={<ProtectedRoute><PlanoCadastro /> </ProtectedRoute>} />

                <Route exact path="/Backend/Lista" element={<ProtectedRoute><Backend /> </ProtectedRoute>} />
                <Route exact path="/Backend/Cadastro/" element={<ProtectedRoute><BackendCadastro /> </ProtectedRoute>} />
                <Route exact path="/Backend/Cadastro/:Guid" element={<ProtectedRoute><BackendCadastro /> </ProtectedRoute>} />

                <Route exact path="/FinanceiroMaster/Entradas" element={<ProtectedRoute><MasterEntradas /> </ProtectedRoute>} />
                <Route exact path="/FinanceiroMaster/Saidas" element={<ProtectedRoute><MasterSaidas /> </ProtectedRoute>} />
                <Route exact path="/FinanceiroMaster/Cronograma" element={<ProtectedRoute><Cronograma /> </ProtectedRoute>} />
               
            </Routes>
        </Layout>
      </Suspense>
   
  );
}