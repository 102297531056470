import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { CacheDB } from './scripts/CacheDB';

const firebaseConfig = {
  apiKey: "AIzaSyCMY9dPRVoBQNK9Jv_fKlPKs6Za9hCMt2A",
  authDomain: "sunundrive.firebaseapp.com",
  projectId: "sunundrive",
  storageBucket: "sunundrive.appspot.com",
  messagingSenderId: "366438780709",
  appId: "1:366438780709:web:c1d6b34346b034f20e14cc"
};

  
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);
const publicKey = "BPq4ZwGcY3ddNWfRgl4mZJ7ImoH65YJ9O9x2IGETB53wjKn0rK5rAfSWq7FnJOUbDVSV9wVZHKPIrukHLQXVnz0";

export const requestForToken = async (setTokenFound) => {
  let currentToken = "";
  let topic = "news";

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    console.log(messaging);
    
    CacheDB.Set("firebase_token", currentToken);
    if (currentToken) {

      setTokenFound(true);

    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
  });


 