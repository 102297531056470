/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { useParams } from 'react-router-dom';
import { Req, Request } from '../../../scripts/Request';
import { Cache} from '../../../scripts/Cache';
import { WaitObject } from '../../../scripts/Wait';
import { useNavigate } from 'react-router-dom';
import StringMask from 'string-mask';
import moment from 'moment/moment';
import ReactGA from 'react-ga';
import './index.css';


const contatos = [
    {id: 1, group: "Financeiro", subject: "Financeiro - Assunto/Duvida Ref. recebimentos", description: ""},
    {id: 2, group: "Financeiro", subject: "Financeiro - Assunto/Duvida Ref. taxas e/ou deduções", description: ""},
    {id: 3, group: "Financeiro", subject: "Financeiro - Assunto/Duvida Ref. liberação de valores", description: ""},
    {id: 4, group: "Financeiro", subject: "Financeiro - Assunto/Duvida Ref. pagamento de bonus", description: ""},
    {id: 5, group: "Financeiro", subject: "Financeiro - Assunto/Duvida Ref. pagamento de comissões", description: ""},
    {id: 6, group: "Cadastro", subject: "Cadastro - Alterar dados Cadastrais", description: ""},
    {id: 7, group: "Cadastro", subject: "Cadastro - Demais assuntos", description: ""},
    {id: 8, group: "Suporte Técnico", subject: "Suporte Técnico - Relatar um problema", description: ""},
    {id: 9, group: "Suporte Técnico", subject: "Suporte Técnico - Demais assuntos e/ou dúvidas", description: ""},
    {id: 10, group: "Suporte Técnico", subject: "SUNUNDRIVE - Problema para acessar o drive/login", description: ""},
    {id: 11, group: "Suporte Técnico", subject: "SUNUNDRIVE - Problema ao fazer upload de arquivos", description: ""},
    {id: 12, group: "Suporte Técnico", subject: "SUNUNDRIVE - Problema ao fazer download de arquivos", description: ""},
    {id: 13, group: "Suporte Técnico", subject: "SUNUNDRIVE - Plataforma Indisponível/Offline", description: ""},
    {id: 14, group: "Suporte Técnico", subject: "SUNUNDRIVE - Demais assuntos e/ou dúvidas", description: ""},
    {id: 15, group: "Ouvidoria", subject: "SUNUNDRIVE - Demais assuntos e/ou dúvidas", description: ""},
    {id: 16, group: "Ouvidoria", subject: "Ouvidoria", description: ""},
];



export default function Index() {
    const component_name = "suporte";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [desc, setDesc] = useState(contatos[0].description);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [form, setForm] = useState({nome: user.nome, email: user.email, telefone:"", mensagem:"", subject_id:1});
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".Contato")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {    
        LoadTickets();
    }
    //#endregion Init


    //#region Loaders
    const LoadTickets = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Suporte/List", "POST", "", user.token, 0))
        .then(async(data) => {
            setPageLoading(false);
            if(data.toString() !== "error") {
                setTotal(data.total);
                setList(data.list);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRE002)", "error");
        });
    }
    //#endregion Loaders


    
    //#region Handlers
    const _HandleChange = (event) => {
        let value = event.target.value;

        if(event.target.id === "subject_id") {
            setDesc(contatos.find(c => c.id === parseInt(event.target.value))?.description);
        } else if(event.target.id === "telefone") {
            value = value.replace(/[^\d]+/g, '');
            let formatter = new StringMask("(99) 99999-9999");
            value = formatter.apply(value);
        }

        setForm({...form, [event.target.id]: value });
    }


    const _HandleSubmit = async(event) => {
        event.preventDefault();
      
        if(form.nome === "" 
        || form.email === ""
        || form.telefone === ""
        || form.mensagem === ""
        || form.subject_id === "") {
            window.swal("Alerta", "Preencha todo formulário!", "error");
            return;
        }

        setPageLoading(true);
        let _result = await Req("api/Suporte/Send", "POST", {...form, ...contatos.find(c => c.id === parseInt(form.subject_id)) }, user.token);
        setPageLoading(false);
        

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Mensagem enviada com sucesso!", "success");
            setTimeout(() => { 
               setForm({nome: user.nome, email: user.email, telefone:"", mensagem:"", subject_id:1});
               LoadTickets();
            }, 300);
        
        } else {
            window.swal("Alerta", "Não foi possível enviar a mensagem, tente novamente mais tarde!", "warning");
        }
    }


    const _HandleImageError = (event) => {
        event.target.src = "/assets/images/users/avatar-3.jpg";
    }

    //#endregion Handlers


    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row Contato">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-sm-12">
                                            <h3>Central de suporte</h3>
                                            
                                        </div>

                                        <div className="mb-3">
                                            <div id={'_nome'} className={'form-group pull-left '}  style={{ width: '50%', padding: '3px', marginTop: 0, display: 'block' }}>
                                                <div className={'form-floating  mb-3 '}>
                                                    <input type="text" className={'form-control'} id="nome" name="nome" required value={form.nome} onChange={_HandleChange} autoComplete="off"/>
                                                    <label htmlFor="input-username">Nome <em>*</em></label> 
                                                </div>
                                            </div>

                                            <div id={'_email'} className={'form-group pull-left '}  style={{ width: '50%', padding: '3px', marginTop: 0, display: 'block' }}>
                                                <div className={'form-floating  mb-3 '}>
                                                    <input type="text" className={'form-control'} id="email" name="email" required value={form.email} onChange={_HandleChange} autoComplete="off"/>
                                                    <label htmlFor="input-username">E-mail <em>*</em></label> 
                                                </div>
                                            </div>

                                            <div id={'_telefone'} className={'form-group pull-left '}  style={{ width: '50%', padding: '3px', marginTop: 0, display: 'block' }}>
                                                <div className={'form-floating  mb-3 '}>
                                                    <input type="text" className={'form-control'} id="telefone" name="telefone" required value={form.telefone} onChange={_HandleChange} autoComplete="off"/>
                                                    <label htmlFor="input-username">Telefone Celular<em>*</em></label> 
                                                </div>
                                            </div>

                                            <div id={'_subject'} className={'form-group pull-left '}  style={{ width: '50%', padding: '3px', marginTop: 0, display: 'block' }}>
                                                <div className={'form-floating mb-3 '}>
                                                    <select id="subject_id" name="subject_id" className={'form-control fg-input pointer '} aria-label="subject" aria-describedby="basic-addon2" value={form.subject_id} onChange={_HandleChange} required>
                                                        {contatos.map((option, i) => (
                                                            <option key={'subject_opti_' + i} value={option.id}>{option.subject}</option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="subject_id">Assunto desejado<em>*</em></label>
                                                </div>
                                            </div>

                                            <div id={'_telefone'} className={'form-group pull-left '}  style={{ width: '100%', padding: '3px', marginTop: 0, display: 'block' }}>
                                                <div className={'form-floating  mb-3 '}>
                                                    <textarea id="mensagem" name="mensagem"style={{ height: '150px' }} className={'form-control fg-input '} defaultValue={form.mensagem}  value={form.mensagem} onChange={_HandleChange} required>{form.mensagem}</textarea>
                                                    <label htmlFor="input-username">Mensagem <em>*</em></label>
                                                </div>
                                            </div>

                                          

                                            <center> 
                                                <br />
                                       
                                                <button className="btn btn-primary mt-3" onClick={_HandleSubmit}><i className='mdi mdi-star-minus'></i> &nbsp; Enviar Mensagem</button>
                                            
                                            </center>

                                            <br />

                                            <hr />
                                            <small className='text-red'>* Seu ticket de suporte terá andamento e atualizações através do seu e-mail registrado no formulário acima.</small>               
                                            <br />
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className="col-xl-3">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="user-profile-img">
                                            <img src={"/assets/images/" + user.current_rank + ".png"} className="profile-img profile-foreground-img rounded-top" style={{height: "120px;"}} alt="" />
                                            <div className="overlay-content rounded-top">
                                                <div>
                                                    <div className="user-nav p-3">
                                                        <div className="d-flex justify-content-end">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="mt-n5 position-relative">
                                            <div className="text-center">
                                                <img src={"/storage/users/" + user?.user_id + ".jpg?" + (Invalidator['profile_image'])} alt="" className="avatar-xl rounded-circle img-thumbnail" onError={_HandleImageError}/>
                                                <div className="mt-3">
                                                    <h5 className="mb-1">{user?.nome}</h5>
                                                
                                                    
                                                </div>

                                            </div>
                                        </div>

                                        <div className="p-3 mt-3">
                                            <div className="row text-center">
                                               
                                                <div className="col-12">
                                                    <div className="p-1">
                                                        <h5 className="mb-1">{total}</h5>
                                                        <p className="text-muted mb-0">Total de tickes</p>
                                                    </div>
                                                </div>
                                            </div>


                                            <hr />
                                            <h4>Últimos tickets</h4>

                                            <p className="text-muted _text-truncate mb-0"></p>
                                            <br />

                                            
                                            {Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                                <div className="card border shadow-none mb-2">
                                                    <div className="p-2">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar align-self-center me-3">
                                                                <div className={"avatar-title rounded font-size-24 " + (item.status===1?'bg-success text-white':'bg-danger text-white')}>
                                                                    <i className={"mdi " +  (item.status===1?'mdi-check':'mdi-exclamation-thick')}></i>
                                                                </div>
                                                            </div>

                                                            <div className="overflow-hidden me-auto">
                                                                <h5 className="font-size-15 text-truncate mb-1">{item.subject}</h5>
                                                                <p className="text-muted text-truncate mb-0">{moment(item.date_updated).format("DD/MM/YYYY HH:mm")} - {(item.status===1?'Fechado':'Aberto')}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}   

                                            <center>
                                            {(!list || ( Array.isArray(list) && list.length === 0)) && (
                                                <div className="card bg-primary text-white text-center p-3">
                                                    <blockquote className="card-blockquote m-0">
                                                        <p>Nenhum ticket de suporte foi gerado até o momento, mas estamos aqui se precisar</p>
                                                        <footer className="blockquote-footer text-white font-size-12 mt-0 mb-0">
                                                            {moment().format("DD/MM/YYYY HH:mm")}
                                                        </footer>
                                                    </blockquote>
                                                </div>
                                            )}  
                                            </center>      
                                        
                                        </div>

                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    )
}